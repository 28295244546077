import { FC } from "react";
import Paper from "../TrueUI/Containers/Paper";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2, Button, Divider, Font } from "../TrueUI";
import Link from "../TrueUI/Typography/Link";
import { usePermissions } from "../../hooks/usePermissions";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../dtos/permissions-enums";

const RecentClaims: FC = () => {
  const navigate = useNavigate();
  const hasPermissionIncident = usePermissions([
    PermissionsEnums.PORTAL_INCIDENT,
  ]).hasPermission;
  const hasPermissionClaim = usePermissions([
    PermissionsEnums.PORTAL_CLAIMS,
  ]).hasPermission;

  const getFilterType = () => {
    if (hasPermissionIncident === true && hasPermissionClaim === true) {
      return "All";
    }
    if (hasPermissionIncident) {
      return "Incident";
    }
    return "Claim";
  };

  const tableName = "recent-claims-table";

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Dashboard/GetRecentClaims?filterType=${getFilterType()}`,
    advancedOptions: {
      paginate: false,
      tableStyle: {
        headerStyle: "normal",
      },
    },
    toolbarOptions: {
      hideToolbar: true,
    },
  };

  useBaseTable(tableConfiguration);

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Font fontType={"HEADING_6"} display="block" textAlign="start">
        Recent Claims
      </Font>
      <Button
        onClick={() => {
          navigate("/incident");
        }}
        sx={{ sm: { fontSize: "10px" } }}
      >
        File a Claim
      </Button>
    </div>
  );

  return hasPermissionIncident || hasPermissionClaim ? (
    <Paper title={title} margin="12px 0" collapse>
      <BaseTable2 name={tableName} />
      <Divider height={"20px"} />
      <Link href={"/claims"} displayValue="View all claims  >" />
    </Paper>
  ) : null;
};

export default RecentClaims;
