import { FC } from "react";
import style from "./Document.module.css";
import Switch from "../Checkbox/Switch";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DownloadLinkButton from "../File/DownloadLinkButton";
import { PortalDocumentDto } from "../../../dtos/portal-document-dto";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import Caption from "../Typography/Caption";
import Link from "../Typography/Link";
import { Launch } from "@mui/icons-material";

export type DocumentComp = {
  document: PortalDocumentDto;
  showFavoriteButton?: boolean;
  onCheckDocument?: (isChecked: boolean, document: PortalDocumentDto) => void;
  onFavoriteClick?: (document: any) => void;
};

const Document: FC<DocumentComp> = ({
  document,
  showFavoriteButton = true,
  onCheckDocument,
  onFavoriteClick,
}) => {
  const favoriteProps = {
    sx: { color: "#FCB833", cursor: "pointer" },
    onClick: () => onFavoriteClick?.(document),
  };

  const documentType = conditionHasValue(document?.documentType)
    ? `[${document?.documentType}]`
    : ``;

  return (
    <div className={"document_container"}>
      {document?.documentType !== "URL" ? (
        <Switch
          inputWidth="34px"
          isChecked={document?.isChecked}
          control="checkbox"
          onChangeIsChecked={(value) => onCheckDocument?.(value, document)}
        />
      ) : null}
      {showFavoriteButton &&
        (document?.isFavorite ? (
          <FavoriteIcon {...favoriteProps} />
        ) : (
          <FavoriteBorderIcon {...favoriteProps} />
        ))}
      <div className={style.content_container}>
        {document?.documentType !== "URL" ? (
          <DownloadLinkButton
            fileName={document?.blobName}
            fileTitle={`${document?.documentTitle} ${documentType}`}
            textAlign="start"
            display="block"
            truncate
          />
        ) : (
          <Link
            id="portal-document"
            name="claim-claimant-email"
            href={`${document?.linkURL}`}
            displayValue={
              <>
                {document?.documentTitle} <Launch fontSize="inherit" />
              </>
            }
            linkFontType="BODY"
            target="_blank"
            truncate
          />
        )}
        <Caption textAlign="start" display="block" truncate>
          {document?.documentDescription}
        </Caption>
      </div>
    </div>
  );
};
export default Document;
