import { PayrollReportAdjustmentTypeEnum } from "../../../dtos/payroll-report-adjustment-type-enum";
import { PayrollReportAdjustmentsDto } from "../../../dtos/payroll-report-adjustments-dto";
import { sumFloat, sumInt } from "../../../utilities/arrayFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  formatNegativeNumbers,
  getNumberAsStringWithComasWithDecimals,
} from "../../../utilities/stringFunctions";
import Input from "../../TrueUI/Inputs/Input";
import { ColumnOptionsProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { TableData, TableRow } from "../../TrueUI/Tables/BaseTableStructure";

export const PayrollReportDetailsTotalsFooter = (
  tableData: any,
  columnOptionsProps: ColumnOptionsProperties<any>[],
  adjustments: PayrollReportAdjustmentsDto[] | null
) => {
  const totalEmployees = sumInt(
    tableData?.map((row) => row?.Metadata?.TotalEmployees ?? 0)
  );
  const totalPayroll = sumFloat(
    tableData?.map((row) => row?.Metadata?.TotalPayroll ?? 0)
  );
  const totalAmount = sumFloat(
    tableData?.map((row) => row?.Metadata?.TotalAmount ?? 0)
  );

  const hasAdjustments =
    conditionHasValue(adjustments) && (adjustments?.length ?? 0) > 0;

  const printCellValue = (
    column,
    totalEmployees,
    totalPayroll,
    totalAmount
  ) => {
    switch (column) {
      case "Description":
        return (
          <Input
            type={"text"}
            id={"total"}
            name={"total"}
            value={hasAdjustments ? "Subtotal" : "Totals"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        );
      case "NumEE":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"number"}
              id={"total-employees"}
              name={"total-employees"}
              value={totalEmployees}
              inputFontType={"BOLD_BODY"}
              align="right"
              readOnly
              key={totalEmployees}
            />
          </div>
        );
      case "NetPayroll":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"fixedCurrency"}
              id={"total-payroll"}
              name={"total-payroll"}
              value={totalPayroll}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
              key={totalPayroll}
            />
          </div>
        );
      case "Amount":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"fixedCurrency"}
              id={"total-amount"}
              name={"total-amount"}
              value={totalAmount}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
              key={totalAmount}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
          justifyContent: "flex-end",
          paddingRight: "50px",
        }}
      >
        {columnOptionsProps?.map((column, key) => (
          <TableData
            key={key}
            width={
              conditionHasValue(column.width) ? `${column.width}%` : "auto"
            }
          >
            {printCellValue(
              column.fieldName,
              totalEmployees,
              totalPayroll,
              totalAmount
            )}
          </TableData>
        ))}
      </TableRow>
      {hasAdjustments &&
        MappedAdjustmentsRows(
          columnOptionsProps,
          totalAmount,
          adjustments ?? []
        )}
    </>
  );
};

const MappedAdjustmentsRows = (
  columns: ColumnOptionsProperties<any>[],
  totalAmount: number,
  payrollReportAdjustments: PayrollReportAdjustmentsDto[]
) => {
  return (
    <>
      {payrollReportAdjustments?.map((adjustment, adjustmentKey) => (
        <TableRow
          key={adjustmentKey}
          style={{
            width: "100%",
            display: "flex",
            fontWeight: "700",
            height: "48px",
            justifyContent: "flex-end",
            paddingRight: "50px",
          }}
        >
          {columns?.map((column, key) => (
            <TableData
              key={key}
              width={
                conditionHasValue(column.width) ? `${column.width}%` : "auto"
              }
            >
              {getCellValueAdjustmentRow(
                adjustment,
                column.fieldName,
                totalAmount
              )}
            </TableData>
          ))}
        </TableRow>
      ))}
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
          justifyContent: "flex-end",
          paddingRight: "50px",
        }}
      >
        {columns?.map((column, key) => (
          <TableData
            key={key}
            width={
              conditionHasValue(column.width) ? `${column.width}%` : "auto"
            }
          >
            {getCellValueAdjustmentTotals(
              column.fieldName,
              getAdjustmentTotal(payrollReportAdjustments ?? [], totalAmount)
            )}
          </TableData>
        ))}
      </TableRow>
    </>
  );
};

const getCellValueAdjustmentRow = (
  adjustment: PayrollReportAdjustmentsDto,
  column: string,
  totalAmount: number
) => {
  const isPercentage =
    adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT ||
    adjustment.adjustmentType === PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT;
  switch (column) {
    case "Description":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"adjustment-description"}
            name={"adjustment-description"}
            value={adjustment.adjustmentDescription}
            inputFontType={"BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "ModifiedRate":
      return isPercentage ? (
        <div
          style={{
            width: "100%",
            paddingRight: adjustment.isEditable ? "0px" : "12px",
          }}
        >
          <Input
            type={"currency"}
            id={"adjustment-value"}
            name={"adjustment-value"}
            value={adjustment.adjustmentValue}
            inputFontType={"BODY"}
            align="right"
            prefix=""
            suffix="%"
            decimalScale={1}
            readOnly
          />
        </div>
      ) : (
        ""
      );
    case "Amount":
      return (
        <div
          style={{
            width: "100%",
            paddingRight: "12px",
          }}
        >
          <Input
            type={isPercentage ? "text" : "fixedCurrency"}
            id={"adjustment-amount"}
            name={"adjustment-amount"}
            value={getAdjustmentFormattedValue(adjustment, totalAmount)}
            inputFontType={"BODY"}
            align="right"
            prefix=""
            decimalScale={2}
            readOnly
          />
        </div>
      );
    default:
      return "";
  }
};

const getCellValueAdjustmentTotals = (column, totalDue) => {
  switch (column) {
    case "Description":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"text"}
            id={"total-due"}
            name={"total-due"}
            value={"Total Due"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        </div>
      );
    case "Amount":
      return (
        <div style={{ width: "100%", paddingRight: "12px" }}>
          <Input
            type={"fixedCurrency"}
            id={"total-amount"}
            name={"total-amount"}
            value={totalDue}
            inputFontType={"BOLD_BODY"}
            align="right"
            prefix=""
            readOnly
          />
        </div>
      );
    default:
      return "";
  }
};

const getAdjustmentFormattedValue = (
  adjustment: PayrollReportAdjustmentsDto,
  total: number
) => {
  switch (adjustment.adjustmentType) {
    case PayrollReportAdjustmentTypeEnum.AMOUNT_CREDIT:
      return formatNegativeNumbers(adjustment.adjustmentAmount * -1);
    case PayrollReportAdjustmentTypeEnum.AMOUNT_DEBIT:
      return getNumberAsStringWithComasWithDecimals(
        adjustment.adjustmentAmount,
        2
      );
    case PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT:
      const calcAmount = (total * adjustment.adjustmentValue) / 100;
      return formatNegativeNumbers(calcAmount * -1);
    case PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT:
      return getNumberAsStringWithComasWithDecimals(
        (total * adjustment.adjustmentValue) / 100,
        2
      );
    default:
      return "";
  }
};

const getAdjustmentTotal = (
  adjustments: PayrollReportAdjustmentsDto[],
  subTotal
) => {
  const totalAmountCredit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.AMOUNT_CREDIT
      ? sum + adjustment.adjustmentAmount
      : sum;
  }, 0);
  const totalAmountDebit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.AMOUNT_DEBIT
      ? sum + adjustment.adjustmentAmount
      : sum;
  }, 0);
  const totalCalcPercentDebit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_DEBIT
      ? sum + (subTotal * adjustment.adjustmentValue) / 100
      : sum;
  }, 0);
  const totalCalcPercentCredit = adjustments.reduce((sum, adjustment) => {
    return adjustment.adjustmentType ===
      PayrollReportAdjustmentTypeEnum.PERCENT_CREDIT
      ? sum + (subTotal * adjustment.adjustmentValue) / 100
      : sum;
  }, 0);

  const total =
    subTotal -
    totalAmountCredit +
    totalAmountDebit -
    totalCalcPercentCredit +
    totalCalcPercentDebit;

  return total > 0 ? total : 0;
};
