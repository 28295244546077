import { colors, fonts, fontsFamily, mediaQueries } from "./themeConstants";
import { Theme } from "./themeTypes";

const diamondTheme: Theme = {
  primary: "#0F977F",
  secondary: "#3FAC99",
  logoPath: "../media/diamond/diamondlogo1.png",
  primaryBackground: "#DBF0EC",
  secondaryBackground: colors.CONCRETE,
  textColor: "#000000",
  secondaryTextColor: "#FFF",
  contrastTextColor: colors.EGGIER,
  borderColor: colors.CONCRETE,
  borderModalColor: "#000000",
  inputBackgroundColor: colors.EGGIER,
  danger: colors.RED,
  warning: colors.YELLOW,
  success: colors.GREEN,
  defaultTheme: "diamondTheme",
  ...fonts,
  ...fontsFamily,
  ...mediaQueries,
};

const defaultTheme: Theme = {
  primary: "#33A0FF",
  secondary: "#2D2D46",
  logoPath: "../media/True_long_LightMode.svg",
  backgroundPath: "../media/true/true-background.png",
  primaryBackground: "#fff",
  secondaryBackground: colors.CONCRETE,
  textColor: "#000000",
  secondaryTextColor: "#FFF",
  contrastTextColor: colors.EGGIER,
  inputBackgroundColor: colors.EGGIER,
  borderColor: colors.CONCRETE,
  borderModalColor: "#000000",
  danger: colors.RED,
  warning: colors.YELLOW,
  success: colors.GREEN,
  defaultTheme: "defaultTheme",
  ...fonts,
  ...fontsFamily,
  ...mediaQueries,
};

const themes = { diamondTheme, defaultTheme };

export default themes;
