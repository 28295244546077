import { FC } from "react";
import { FontsType } from "../../../media/themeTypes";
import RadioGroup from "@mui/material/RadioGroup";
import { KeysAsType } from "../../../types/KeysAsAType";
import Font from "../Typography/Font";
import { FormControl, FormControlLabel, Radio } from "@mui/material";

export type RadioButtonOptionsType = {
  value?: number | string;
  label?: string;
};

type RadioGroupProps = {
  label?: string;
  options: RadioButtonOptionsType[];
  labelFontType?: KeysAsType<FontsType>;
  labelAlign?: "start" | "center" | "end";
  horizontalAlign?: boolean;
  value?: string | number;
  errorMessages?: any;
  onChange?: (e) => void;
  onChangeRaw?: (value) => void;
};

const TrueRadioGroup: FC<RadioGroupProps> = ({
  labelFontType,
  labelAlign = "start",
  label,
  options,
  horizontalAlign = true,
  value = options?.[0]?.value ?? 0,
  onChange,
  onChangeRaw,
}) => {
  const onChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    onChangeRaw?.((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <Font textAlign={labelAlign} fontType={labelFontType}>
        {label}
      </Font>
      <RadioGroup
        row={horizontalAlign}
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={value}
        onChange={onChangeForm}
      >
        {options.map((option: RadioButtonOptionsType) => (
          <FormControlLabel
            value={option?.value}
            control={<Radio />}
            key={option?.value}
            label={<Font fontType={"BODY"}>{option?.label}</Font>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default TrueRadioGroup;
