import { Box, Container } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Heading3 } from "../TrueUI";
import { useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { PortalClaimDetailPage } from "../../dtos/portal-claim-detail-page";
import ClaimInformation from "./ClaimDetail/ClaimInformation";
import { useParams } from "react-router";
import ClaimFinancial from "./ClaimDetail/ClaimFinancial";
import ClaimNotes from "./ClaimDetail/ClaimNotes";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import UploadedFiles from "./ClaimDetail/UploadedFiles/UploadedFiles";
import AuthError from "../../AuthError";

const ClaimDetail: FC = () => {
  const { claimId } = useParams();
  const localOptions = useRecoilValue(globalOptions);

  const { responseGet, dispatchGet } = useApiGet<PortalClaimDetailPage>(
    `api/Claims/GetClaimDetail?claimId=${claimId}`
  );

  const [claimDetailPageInfo, setClaimDetailPageInfo] =
    useState<PortalClaimDetailPage | null>(null);

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet))
      setClaimDetailPageInfo(responseGet.responseData ?? null);
  }, [responseGet]);

  const programInfo = localOptions?.initOptions?.programInfo;
  return claimDetailPageInfo?.hasUserAccess === true ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0 24px",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Heading3 textAlign="start" display="block" whiteSpace="normal">
          Claims
        </Heading3>
      </div>
      <Container sx={{ minWidth: "300px" }} maxWidth="lg">
        <Box sx={{ height: "auto", margin: "0", padding: "0" }}>
          <ClaimInformation
            claimInformation={claimDetailPageInfo?.claimInformation}
          />
          <ClaimFinancial
            financialTableData={claimDetailPageInfo?.financialTableData}
            claimFinancialTotals={claimDetailPageInfo?.claimFinancialTotals}
          />
          <ClaimNotes
            showPanel={programInfo?.portalClaimShowNotes}
            diaryNoteList={claimDetailPageInfo?.diaryNoteList ?? []}
          />
          <UploadedFiles
            claimId={claimId}
            showPanel={claimDetailPageInfo?.showFilesPanel ?? false}
            adjusterMail={claimDetailPageInfo?.claimInformation?.assigneeEmail}
            claimNumber={claimDetailPageInfo?.claimInformation?.claimNumber}
          />
        </Box>
      </Container>
    </div>
  ) : (
    <AuthError unauthorizedRoute={`/claims/${claimId}`} />
  );
};

export default ClaimDetail;
