import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { usePermissions } from "../../../hooks";
import EyeBallIcon from "@mui/icons-material/VisibilityOutlined";
import EyeBallOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import Input from "./Input";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import "./inputTax.module.css";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type InputTaxAutoformatProps = {
  id: string;
  name: string;
  label?: string;
  value?: string;
  taxIdType?: "SSN" | "FEIN";
  className?: string;
  readOnly?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
  variant?: "filled" | "standard" | "outlined";
  align?: "left" | "center" | "right";
  permissions?: number[];
  errorMessage?: string[] | null;
  inputWidth?: string;
  helperText?: string;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  onChange?: (e) => void;
  onBlur?: (e?: any) => void;
  tabIndex?: number;
  isAutoComplete?: boolean;
  htmlLabel?: string;
};
/**
 * This component doesn't determinate the taxIdType based on the taxIdValue
 *
 * This component formats the taxIdValue based on the taxIdType. Since the taxIdType will
 * always be the same (It won't change after user enters the value), users will only
 * be allowed to enter numbers (no hyphen), and the input will be formatted as either an
 * SSN or an FEIN
 */
const InputTaxAutoformat: FC<InputTaxAutoformatProps> = ({
  id = "",
  name = "",
  label = "",
  value = "", // this is the taxId with no formatting, ex: 123456789
  taxIdType = "SSN",
  readOnly = false,
  labelPosition,
  variant,
  permissions = [1, 2, 3], // TODO: @elara Update until Antonio's PR will be ready
  onChange,
  onBlur,
  errorMessage = null,
  inputWidth = "100%",
  helperText = "",
  align,
  inputFontType,
  labelFontType,
  tabIndex = 0,
  isAutoComplete = true,
  htmlLabel,
}) => {
  const hasPermission = usePermissions(permissions);
  const [showTaxId, setShowTaxId] = useState(!readOnly);

  useEffect(() => {
    setShowTaxId(!readOnly);
  }, [readOnly]);

  const handleTaxChange = (event: any) => {
    const newValue = event.target.value;
    onChange?.(newValue.replace(/\D/g, ""));
  };
  const formatNumberByTaxIdType = (inputValue: string) => {
    const numericValue = inputValue.replace(/\D/g, "");
    if (taxIdType === "FEIN") {
      // The FEIN taxId is formatted by two parts ##-#######
      const firstPart = numericValue.substring(0, 2);
      const secondPart = `${numericValue.substring(2)}`;
      return `${firstPart}${secondPart ? `-${secondPart}` : ""}`;
    } else if (taxIdType === "SSN") {
      // The SSN taxId is formatted by three parts ###-##-####
      const firstPart = numericValue.substring(0, 3);
      const secondPart = `${numericValue.substring(3, 5)}`;
      const thirdPart = `${numericValue.substring(5, 9)}`;
      return `${firstPart}${secondPart ? `-${secondPart}` : ""}${
        thirdPart ? `-${thirdPart}` : ""
      }`;
    }
    return numericValue;
  };

  const maskTaxId = (inputValue: string) => {
    const taxIdFormatted = formatNumberByTaxIdType(inputValue);
    if (showTaxId === false && taxIdType === "SSN") {
      return ssnMask(taxIdFormatted);
    } else if (showTaxId === false && taxIdType === "FEIN") {
      return feinMask(taxIdFormatted);
    } else {
      return taxIdFormatted;
    }
  };

  const ssnMask = (taxIdFormatted: string) =>
    taxIdFormatted.replace(/^(\d{3})-(\d{2})-(\d{4})$/, "XXX-XX-$3");

  const feinMask = (taxIdFormatted: string) =>
    taxIdFormatted.replace(/^(\d{2})-(\d{3})(\d{4})$/, "XX-XXX$3");

  return (
    <Box
      className={`true_input_tax ${readOnly ? "true_input_tax_read_only" : ""}`}
    >
      <Input
        tabIndex={tabIndex}
        className="true_base_input_tax_id"
        id={id}
        name={name}
        sx={{ width: readOnly === true ? "calc(100% - 38px)" : "100%" }}
        value={conditionHasValue(value) ? maskTaxId(value) : ""}
        label={label}
        htmlLabel={htmlLabel}
        labelPosition={labelPosition}
        variant={variant}
        inputWidth={inputWidth}
        errorMessage={errorMessage}
        helperText={helperText}
        readOnly={hasPermission ? readOnly : true}
        onChange={handleTaxChange}
        onBlur={onBlur}
        maxLength={taxIdType === "FEIN" ? 10 : 11}
        align={align}
        inputFontType={inputFontType}
        labelFontType={labelFontType}
        isAutoComplete={isAutoComplete}
      />
      {hasPermission && value !== "" && readOnly === true && (
        <div className={"true_icons_container"}>
          <IconButton
            onClick={() => setShowTaxId(!showTaxId)}
            {...{ "true-element": `true-button-${name}ShowHide` }}
          >
            {showTaxId ? <EyeBallOffIcon /> : <EyeBallIcon />}
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default InputTaxAutoformat;
