import { FC } from "react";
import style from "../Styles/basegridcells.module.css";
import { useFileRequestInstance } from "../../../hooks";

type DefaultDownloadLinkCellProperties = {
  value: string;
  displayValues: any[];
};
const DefaultDownloadLinkCell: FC<DefaultDownloadLinkCellProperties> = ({
  value,
  displayValues,
}) => {
  const foundDisplayValueIndex =
    displayValues.map((v) => v.guid).indexOf(value) ?? -1;
  const [linkDisplayName, linkPath] = displayValues[foundDisplayValueIndex]
    ?.displayValuesOrFieldNames ?? [null, null, null];

  const { downloadFile } = useFileRequestInstance();
  return (
    <div
      className={style.default_link_render_only_cell}
      onClick={() => downloadFile(linkPath)}
    >
      {linkDisplayName}
    </div>
  );
};

export default DefaultDownloadLinkCell;
