import WelcomeText from "./WelcomeText";
import CurrentBilling from "./CurrentBilling";
import ActivePolicies from "./ActivePolicies";
import RecentClaims from "./RecentClaims";
import { usePermissions } from "../../hooks";
import { PermissionsEnums } from "../../dtos/permissions-enums";

const Dashboard = () => {
  const hasCurrentBillingPermission = usePermissions([
    PermissionsEnums.PORTAL_BILLING,
  ]).hasPermission;

  const hasActivePoliciesPermission = usePermissions([
    PermissionsEnums.PORTAL_POLICY,
  ]).hasPermission;

  return (
    <>
      <WelcomeText />
      {hasCurrentBillingPermission === true && <CurrentBilling />}
      {hasActivePoliciesPermission === true && <ActivePolicies />}
      <RecentClaims />
    </>
  );
};

export default Dashboard;
