import { FC } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { PermissionsEnums } from "../../dtos/permissions-enums";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import AuthError from "../../AuthError";

type AuthRouteProperties = {
  defaultPermissions: PermissionsEnums[];
  routesOptions: {
    path: string;
    element: JSX.Element;
    permissions?: number[] | PermissionsEnums[];
    ignorePermissions?: PermissionsEnums[];
    allowedEnvironments?: string[];
    noAuth?: boolean;
  }[];
};

const AuthenticatedRoutes: FC<AuthRouteProperties> = ({
  routesOptions: routes,
  defaultPermissions,
}) => {
  const sourceLocation = useLocation();
  const { initOptions: userOptions } = useRecoilValue(globalOptions);
  const hasAuthenticatedError = (
    noAuth: boolean,
    permissions?: number[] | PermissionsEnums[]
  ) => {
    const hasDefaultPermissions = (userOptions?.permissions ?? []).some(
      (p) => defaultPermissions.indexOf(p) >= 0
    ) as boolean;

    const hasSpecificPermissions = (userOptions?.permissions ?? []).some(
      (p) => (permissions ?? []).indexOf(p) >= 0
    ) as boolean;

    const hasPermission =
      (permissions?.length ?? 0) === 0 || hasDefaultPermissions // If there are no requirements on a route, we will allow this route to be accessible by default, otherwise, validate against user permissions.
        ? true
        : hasSpecificPermissions;

    return noAuth === false && hasPermission === false;
  };

  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route
            key={crypto.randomUUID()}
            path={route.path}
            element={
              hasAuthenticatedError(
                route?.noAuth ?? false,
                route.permissions ?? []
              ) ? (
                <Navigate
                  replace={true}
                  to="/auth-error"
                  state={{ originalRoute: sourceLocation?.pathname ?? "" }}
                />
              ) : (
                route.element
              )
            }
          />
        );
      })}
      <Route
        path={"/auth-error"}
        element={
          <AuthError
            unauthorizedRoute={
              sourceLocation?.state?.originalRoute ?? "INVALID_PATHNAME"
            }
          />
        }
      />
    </Routes>
  );
};

export default AuthenticatedRoutes;
