import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Avatar } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import Font from "../Typography/Font";
import FontBold from "../Typography/FontBold";
import {
  getAvatarStyle,
  getDotStyle,
  getPrevDotStyle,
  progressStepperStyles,
} from "./ProgressStepperStyles";
import style from "./ProgressStepper.module.css";

type ProgressStepperProps = {
  dots: string[];
  active: number;
  lastSavedSection: number;
  allDotsAvailable: boolean;
  onClickDot: (clickedDot: number) => void;
  disableAllDots?: boolean;
};

const isActive = (idx: number, active: number) => idx === active;

const isPreviousDot = (idx: number, finishedSection: number) =>
  idx <= finishedSection;

// const isAfterTheFinishedDot = (idx: number, finishedSection: number) =>
//   idx >= finishedSection + 1;

const isNextDotAfterTheFinishedDot = (idx: number, finishedSection: number) => {
  return idx === finishedSection + 1;
};

const onDotClick = (
  idx: number,
  lastSavedSection: number,
  allDotsAvailable: boolean,
  onClickDot: any
) => {
  if (allDotsAvailable) {
    return onClickDot(idx);
  }
  return lastSavedSection > 0 &&
    (isPreviousDot(idx, lastSavedSection) ||
      isNextDotAfterTheFinishedDot(idx, lastSavedSection))
    ? onClickDot(idx)
    : false;
};

const ProgressStepper: FC<ProgressStepperProps> = ({
  dots,
  active,
  lastSavedSection,
  allDotsAvailable,
  onClickDot,
  disableAllDots = false,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const timelineConfiguration: any = {
    sx: { ...progressStepperStyles(theme) },
  };
  return (
    <Timeline {...timelineConfiguration}>
      {dots.map((dot, idx: number) => (
        <TimelineItem key={`${dot} ${idx}`}>
          <TimelineSeparator>
            <TimelineDot
              onClick={() =>
                onDotClick(idx, lastSavedSection, allDotsAvailable, onClickDot)
              }
              variant="outlined"
              sx={
                isPreviousDot(idx, active)
                  ? getPrevDotStyle(onClickDot, theme)
                  : getDotStyle(isActive(idx, active), theme)
              }
              className={`${disableAllDots ? style.disable_stepper_dot : ""}`}
            >
              <Avatar
                sx={getAvatarStyle(
                  isActive(idx, active),
                  theme,
                  isPreviousDot(idx, lastSavedSection)
                )}
              >
                {idx + 1}
              </Avatar>
            </TimelineDot>
            {dots.length > 1 && idx < dots.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            {isActive(idx, active) ? (
              <FontBold>{dot}</FontBold>
            ) : (
              <Font>{dot}</Font>
            )}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default ProgressStepper;
