import { SxProps, Theme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import Input from "./Input";

type inputPhoneProps = {
  id: string;
  name: string;
  inputKey?: string;
  phoneLabel?: string;
  hideLabelExt?: boolean;
  errorMessagePhone?: string[] | null;
  errorMessageExtension?: string[] | null;
  helperText?: string;
  phoneValue?: string;
  extValue?: string;
  hasExtension?: boolean;
  className?: string;
  readOnly?: boolean;
  phoneFocus?: boolean;
  extensionFocus?: boolean;
  variant?: "filled" | "standard" | "outlined";
  align?: "left" | "center" | "right";
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  permissions?: number[];
  displayInline?: boolean;
  isLabelInline?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
  maxLength?: number;
  inputWidth?: string;
  sx?: SxProps<Theme>;
  disableUnderline?: boolean;
  onChange?: (e) => void;
  onChangePhoneValue?: (e?: any) => void;
  onChangeExtensionValue?: (e?: any) => void;
  onKeyUp?: (e?: any) => void;
  onPhoneBlur?: (e?: any) => void;
  onExtensionBlur?: (e?: any) => void;
  onClick?: (e?: any) => void;
  tabIndex?: number;
  isAutoComplete?: boolean;
  htmlLabel?: string;
};

const InputPhone: FC<inputPhoneProps> = ({
  id = "",
  readOnly,
  phoneFocus,
  extensionFocus,
  phoneLabel = "",
  errorMessagePhone = null,
  errorMessageExtension = null,
  helperText,
  hideLabelExt = false,
  hasExtension = false,
  phoneValue,
  extValue,
  onChange,
  onChangePhoneValue,
  onChangeExtensionValue,
  onKeyUp,
  onPhoneBlur,
  onExtensionBlur,
  onClick,
  variant,
  className,
  labelPosition,
  inputWidth = "100%",
  permissions,
  sx,
  name,
  align,
  inputFontType,
  labelFontType,
  tabIndex = 0,
  isAutoComplete = true,
  htmlLabel,
}) => {
  const onChangePhoneInput = (e) => {
    onChange?.(e);
    onChangePhoneValue?.(e.target.value);
  };

  const onChangeExtensionInput = (e) => {
    onChange?.(e);
    onChangeExtensionValue?.(e.target.value);
  };

  return (
    <div
      className="true_input_phone_general_container"
      style={{ width: inputWidth }}
    >
      <Grid display={"flex"} className={"true_input_phone_container"}>
        <Grid className={"true_phone_container"} sx={{ width: "100%" }}>
          <>
            <Input
              tabIndex={tabIndex}
              id={`${id}`}
              name={`${name}-phone`}
              sx={sx}
              label={phoneLabel}
              htmlLabel={htmlLabel}
              focus={phoneFocus ?? false}
              type="internalPhone"
              value={phoneValue}
              variant={variant}
              errorMessage={errorMessagePhone}
              readOnly={readOnly}
              onChange={(e) => {
                onChangePhoneInput?.(e);
              }}
              onKeyUp={onKeyUp}
              onBlur={(e) => {
                onPhoneBlur?.(e);
              }}
              onClick={onClick}
              maxLength={14}
              labelPosition={labelPosition}
              className={`${className}-phone`}
              helperText={helperText}
              inputWidth={"100%"}
              permissions={permissions}
              align={align}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              isAutoComplete={isAutoComplete ?? true}
            />
          </>
        </Grid>
        {hasExtension && ( //EXTENSION field
          <Grid
            className={"true_extension_container"}
            sx={{ marginLeft: "5px" }}
          >
            <Input
              tabIndex={tabIndex + 1}
              id={`${id}-extension`}
              name={`${name}-extension`}
              className={`${className}-extension`}
              sx={sx}
              label={hideLabelExt == false ? "Ext" : ""}
              focus={extensionFocus ?? false}
              value={extValue}
              type={"internalPhoneExt"}
              variant={variant}
              errorMessage={errorMessageExtension}
              readOnly={readOnly}
              onChange={(e) => {
                onChangeExtensionInput?.(e);
              }}
              onKeyUp={onKeyUp}
              onBlur={(e) => {
                onExtensionBlur?.(e);
              }}
              onClick={onClick}
              maxLength={5}
              labelPosition={labelPosition}
              helperText={helperText}
              permissions={permissions}
              inputFontType={inputFontType}
              labelFontType={labelFontType}
              isAutoComplete={isAutoComplete ?? true}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default InputPhone;
