import { FC } from "react";
import { ClaimDiaryNoteDto } from "../../../dtos/claim-diary-note-dto";
import Note from "./Note";
import { Title } from "../../TrueUI";
import style from "./claim_notes.module.css";
import Paper from "../../TrueUI/Containers/Paper";

type ClaimNotesProps = {
  diaryNoteList: ClaimDiaryNoteDto[];
  showPanel?: boolean;
};

const ClaimNotes: FC<ClaimNotesProps> = ({ diaryNoteList, showPanel }) => {
  return showPanel ? (
    <Paper
      title="Notes"
      margin="25px 0"
      collapse
      childrenContainerHeight="150px"
    >
      <div id={"claim-notes"} className={style.true_conversation_wrapper}>
        {diaryNoteList?.length > 0 ? (
          diaryNoteList?.map((note: ClaimDiaryNoteDto) => (
            <div key={note.diaryNoteId}>
              <Note information={note} />
            </div>
          ))
        ) : (
          <div className={style.true_empty_message_container}>
            <Title>There are no notes yet!</Title>
          </div>
        )}
      </div>
    </Paper>
  ) : null;
};

export default ClaimNotes;
