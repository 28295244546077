import { Alert } from "@mui/material";
import { Col, Row } from "./components/TrueUI";
import { FC } from "react";
import { useLocation } from "react-router";

type AuthErrorProperties = {
  unauthorizedRoute?: string;
};

const AuthError: FC<AuthErrorProperties> = ({ unauthorizedRoute }) => {
  const sourceLocation = useLocation();
  return (
    <Row>
      <Col>
        <Alert variant="outlined" severity="error">
          <div style={{ textAlign: "center" }}>
            You are not authorized to view "
            <strong>
              {unauthorizedRoute ??
                sourceLocation?.state?.originalRoute ??
                "NO_PATH_FOUND"}
            </strong>
            ".
          </div>
          <hr />
          <div>
            If you believe this is in error please contact your administrator.
          </div>
        </Alert>
      </Col>
    </Row>
  );
};

export default AuthError;
