import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Drawer, IconButton, Toolbar } from "@mui/material";
import { FC, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { globalOptions, navBarActiveSection } from "../../GlobalAtoms";
import NavContainer from "./NavContainer";
import NavDrawerContent from "./NavDrawerContent";
import NavBarElements, { NavItemType } from "./NavBarElements";
import { usePermissions } from "../../hooks";
import AgencyNavBarElements from "./AgencyNavBarElements";
import { useNavigate } from "react-router";
import { PermissionsEnums } from "../../dtos/permissions-enums";

export enum NavBarEnum {
  HOME = 0,
  MY_POLICIES = 1,
  CLAIMS = 2,
  BILLING = 3,
}

type NavBarProps = {
  isAgencyPortal?: boolean;
};

const NavBar: FC<NavBarProps> = ({ isAgencyPortal = false }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [_navBarActiveSection, _setNavBarActiveSection] =
    useRecoilState(navBarActiveSection);

  const onDrawerOpen = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const navItems: NavItemType[] = [
    { displayName: "Home", url: "/", hasPermission: true },
    {
      displayName: "My Policies",
      url: "/policy",
      hasPermission: usePermissions([PermissionsEnums.PORTAL_POLICY])
        .hasPermission,
    },
    {
      displayName: "Claims",
      url: "/claims",
      hasPermission: usePermissions([
        PermissionsEnums.PORTAL_CLAIMS,
        PermissionsEnums.PORTAL_INCIDENT,
      ]).hasPermission,
    },
    {
      displayName: "Billing",
      url: "/billing",
      hasPermission: usePermissions([PermissionsEnums.PORTAL_BILLING])
        .hasPermission,
    },
  ];

  const agencyNavItems: NavItemType[] = [
    { displayName: "Home", url: "/", hasPermission: true },
    {
      displayName: "Submit New Business",
      url: "/submit-new-business",
      hasPermission: true,
    },
    {
      displayName: "Get An Indication",
      url: "/get-an-indication",
      hasPermission: true,
    },
  ];

  return (
    <div style={{ display: "flex" }}>
      <AppBar
        component="nav"
        position="relative"
        sx={{ backgroundColor: theme?.secondary }}
      >
        <Toolbar
          component={({ children }) => (
            <NavContainer children={children} isAgencyPortal={isAgencyPortal} />
          )}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onDrawerOpen}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className={"logo-container"} onClick={() => navigate("/")}>
            <img
              style={{ height: "70px" }}
              src={theme?.logoPath}
            ></img>
          </div>
          {isAgencyPortal ? (
            <AgencyNavBarElements />
          ) : (
            <NavBarElements
              navItems={navItems}
              activeSection={_navBarActiveSection}
              setActiveSection={_setNavBarActiveSection}
            />
          )}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={onDrawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: "30%",
            },
          }}
        >
          <NavDrawerContent
            navItems={isAgencyPortal ? agencyNavItems : navItems}
            onDrawerOpen={onDrawerOpen}
          />
        </Drawer>
      </Box>
    </div>
  );
};

export default NavBar;
