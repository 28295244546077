import { FC, useEffect, useState } from "react";
import { BaseGridProperties } from "../../../BaseGrid/BaseGridProperties";
import { useBaseGrid } from "../../../BaseGrid/Hooks/useBaseGrid";
import BaseGrid from "../../../BaseGrid/BaseGrid";

type UploadedFilesTableProps = {
  claimId?: string;
  refresh: boolean;
};

const UploadedFilesTable: FC<UploadedFilesTableProps> = ({
  claimId,
  refresh,
}) => {
  const [isMounted, setIsMounted] = useState(true);
  const gridName = "uploaded-files-table";

  const tableConfigurationGRID: BaseGridProperties = {
    name: gridName,
    getURL: `api/Claims/GetClaimFilesByUserType?claimId=${claimId}`,
    useManuallyReloadParameters: true,
    columnOptions: [
      { fieldName: "File", width: 50 },
      { fieldName: "Category", width: 25 },
      { fieldName: "AddedBy", width: 25 },
    ],
    advancedOptions: {
      disableSortOrder: true,
    },
    toolbarOptions: {
      showExportButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showSaveButton: false,
    },
  };

  const { mountGrid, unmountGrid } = useBaseGrid(tableConfigurationGRID);

  useEffect(() => {
    if (refresh) {
      unmountGrid();
      setIsMounted(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (isMounted === false) {
      mountGrid();
      setIsMounted(true);
    }
  }, [isMounted]);


  return <BaseGrid name={gridName} />;
};

export default UploadedFilesTable;
