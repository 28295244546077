import { FC, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Switch as MaSwitch,
  Radio,
  FormGroup,
  TypographyProps,
  SxProps,
  Theme,
  FormHelperText,
} from "@mui/material";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import Font from "../Typography/Font";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from "@mui/icons-material";
import { isAcceptKey } from "./CheckboxUtils";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import "./switch.module.css";

type SwitchProperties = {
  id?: string;
  label?: string;
  isChecked?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isCheckboxIndeterminate?: boolean;
  control?: "checkbox" | "switch" | "radio";
  labelPlacement?: "end" | "start" | "top" | "bottom";
  labelStyle?: SxProps<Theme>;
  labelFontType?: KeysAsType<FontsType>;
  labelTypographyProps?: { typography?: TypographyProps };
  name?: string;
  trueElement?: string;
  helperText?: string;
  readOnly?: boolean;
  inputWidth?: string;
  spaceBetweenLabelAndControl?: boolean;
  labelAlignment?: "left" | "center" | "right";
  primaryLabelColor?: boolean;
  className?: string;
  controlClassName?: string;
  isTableCell?: boolean;
  onChangeIsChecked?: (e?: any) => void;
  onChange?: (e?: any) => void;
  tabIndex?: number;
  htmlLabel?: string;
};

const labelDefaultStyle = {
  marginLeft: 0,
};

const labelDefaultTypographyProps: any = {
  typography: { textAlign: "left", marginLeft: 0 },
};

const Switch: FC<SwitchProperties> = ({
  id,
  label,
  trueElement,
  isChecked,
  isInvalid = false,
  isDisabled = false,
  isCheckboxIndeterminate = false,
  control,
  labelPlacement,
  labelFontType = "BOLD_BODY",
  onChange,
  onChangeIsChecked,
  labelStyle = labelDefaultStyle,
  labelTypographyProps = labelDefaultTypographyProps,
  name,
  helperText,
  readOnly,
  inputWidth = "100%",
  spaceBetweenLabelAndControl,
  labelAlignment = "left",
  primaryLabelColor = false,
  className,
  controlClassName,
  isTableCell = false,
  tabIndex = 0,
  htmlLabel,
}) => {
  const [showHelpText, setShowHelpText] = useState<boolean>(false);

  const controlInputProps = {
    id: id ?? name,
    tabIndex: tabIndex,
  };

  const getControl = (controlSwitch, inputProps) => {
    switch (controlSwitch) {
      case "checkbox":
        return (
          <Checkbox
            inputProps={inputProps}
            icon={<CheckBoxOutlineBlank />}
            disabled={isDisabled}
            checkedIcon={
              isCheckboxIndeterminate ? <IndeterminateCheckBox /> : <CheckBox />
            }
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
            title={label ?? ""}
            className={`${TABBABLE_CLASS_NAME} ${controlClassName}`}
          />
        );
      case "radio":
        return (
          <Radio
            color="primary"
            disabled={isDisabled}
            inputProps={inputProps}
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
            className={TABBABLE_CLASS_NAME}
          />
        );
      default:
        return (
          <MaSwitch
            color="primary"
            disabled={isDisabled}
            inputProps={inputProps}
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
            className={TABBABLE_CLASS_NAME}
          />
        );
    }
  };

  const getAlignmentClass = () => {
    switch (labelAlignment) {
      case "center":
        return "align-label-center";
      case "right":
        return "align-label-right";
      default:
        return "align-label-left";
    }
  };

  const getStateOfControl = () => {
    if (control === "switch") {
      return isChecked ? "switch-selected" : "switch-unselected";
    } else {
      if (isInvalid) {
        return "invalid";
      }
      if (isDisabled) {
        return "disabled";
      } else if ((!readOnly && isChecked) || (isTableCell && isChecked)) {
        return "selected";
      } else if ((!readOnly && !isChecked) || (isTableCell && !isChecked)) {
        return "unselected";
      }
      return "disabled";
    }
  };

  const onSwitchChange = (event, source: "change" | "keyUp") => {
    if (source === "keyUp" && isAcceptKey(event)) {
      onChangeIsChecked?.(!isChecked);
      onChange?.({ target: { checked: !isChecked } });
    }
    if (source === "change") {
      onChangeIsChecked?.(event.target.checked);
      onChange?.(event);
    }
  };

  return (
    <FormGroup sx={{ width: inputWidth }}>
      <FormControlLabel
        className={`true_switch_container ${className} ${getStateOfControl()} ${
          spaceBetweenLabelAndControl ? "space_between_label_control" : ""
        } `}
        disabled={readOnly}
        onMouseEnter={() => {
          setShowHelpText(true);
        }}
        onMouseLeave={() => {
          setShowHelpText(false);
        }}
        control={getControl(control, controlInputProps)}
        title={label}
        label={
          label != null ||
          (htmlLabel !== null && (
            <Font
              name={name}
              className={`true_switch_label ${
                labelFontType === undefined ? "withoutSize" : ""
              } ${getAlignmentClass()}`}
              primaryColor={primaryLabelColor}
              fontType={labelFontType}
              whiteSpace={"nowrap"}
              truncate
              htmlText={htmlLabel}
            >
              {label}
            </Font>
          ))
        }
        labelPlacement={labelPlacement}
        checked={isChecked ?? false}
        onChange={(e: any) => {
          onSwitchChange(e, "change");
        }}
        onKeyUp={(e) => onSwitchChange(e, "keyUp")}
        sx={{
          ...labelStyle,
          width: inputWidth,
        }}
        componentsProps={labelTypographyProps}
        {...controlInputProps}
      />
      {helperText && (
        <FormHelperText>{showHelpText ? helperText : " "}</FormHelperText>
      )}
    </FormGroup>
  );
};

export default Switch;
