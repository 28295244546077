import { FC, useEffect } from "react";
import { useApiGet } from "../../hooks";
import { useGridInstance } from "./Hooks/useGridInstance";
import { useRecoilValue } from "recoil";
import { RowKey } from "./BaseGridProperties";
import { BaseGridInternalColumnDefaultConfiguration } from "./BaseGridInternalColumnDefaultConfiguration";
import { BaseTableColumn } from "../../dtos/base-table-column";

import { BaseGridFilterParameters } from "./Hooks/useBaseGridFilters";
import {
  COLUMN_EXTENSION_COMPARE_IDENTIFIER,
  COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY,
} from "./BaseGridEnums";
import { FILTER_GROUP_ID } from "./BaseGridConstants";

// import { GridCellManagerInstanceAtomFamily } from "./GridAtoms";

type BaseGridDataProcessorProperties = {
  uiid?: string;
};

const BaseGridDataProcessor: FC<BaseGridDataProcessorProperties> = ({
  uiid,
}) => {
  const { instanceSelector, instanceInternalSelector, setEntireInstance } =
    useGridInstance(uiid ?? "NO_UIID_FOUND", "BaseGridDataProcessor");
  const instance = useRecoilValue(instanceSelector());
  const instanceInternal = useRecoilValue(instanceInternalSelector());

  const { responseGet, dispatchGet } = useApiGet<any>(
    instance.getURL ?? "NO_URL_PROVIDED_ERROR"
  );

  const processColumnsAndData = (
    providedColumns: BaseTableColumn[],
    providedData: string[][]
  ): any => {
    if (providedColumns.length > 0) {
      const start = performance.now();
      const cols =
        [
          BaseGridInternalColumnDefaultConfiguration._rowKeyColumn,
          {
            ...BaseGridInternalColumnDefaultConfiguration._indicationColumn,
            isHidden:
              instance.advancedOptions?.indicationColumnConfiguration
                ?.isHidden ?? true,
          },
        ]
          .concat(
            providedColumns
              .map((pc, index) => {
                // return { ...pc, _externalColumnIndex: index };
                //return {...pc, _externalColumnIndex: index ?? -1 };
                return {
                  ...pc,
                  _externalColumnIndex: index,
                } as BaseTableColumn;
              })
              .concat([
                {
                  ...BaseGridInternalColumnDefaultConfiguration._optionsColumn,
                  isHidden:
                    instance.advancedOptions?.optionsColumnConfiguration
                      ?.isHidden ?? true,
                  _isInternal: true,
                },
                BaseGridInternalColumnDefaultConfiguration._isRowNewColumn,
                BaseGridInternalColumnDefaultConfiguration._isRowDeletedColumn,
              ])
          )
          .map((c, i) => {
            // We need to move the below logic somewhere else. It's important just not at this point because it breaks the last return in this map

            const foundColumn = instance.columnOptions?.find(
              (co) => co.fieldName === c.fieldName
            );

            // Needs review
            // if (foundColumn === undefined || foundColumn === null) {
            //   console.warn(
            //     `The provided field name in the list of columnOptions was not found in the list of available columns.`,
            //     {
            //       columns: instance.columns,
            //       columnOptions: instance.columnOptions,
            //     }
            //   );
            // }

            return {
              ...c,
              _columnIndex: i,
              _visibleColumnIndex: i,
              displayName:
                foundColumn?.displayNameOverride ?? c.displayName ?? "",
              isEditable: foundColumn?.isEditable ?? false,
            };
          }) ?? [];

      // const indexedColumns = addColumnIndexByArrayOrder(cols);

      const rowKeys: RowKey[] = [];

      const data = providedData.map((row, index) => {
        // const rowKey = `${index}_${uiid}`; // See note about the dataSetKey property
        const rowKey = `${index}_${instanceInternal.dataSetKey}`;
        rowKeys.push(rowKey);
        return [
          rowKey,
          instance.advancedOptions?.indicationColumnConfiguration
            ?.indicationType ?? "none",
        ].concat(
          row.concat([
            instance.advancedOptions?.optionsColumnConfiguration?.optionType ??
              "none",
            "false",
            "false",
          ])
        );
      });

      const computeCountResults = (instance.columnOptions ?? []).map((co) => {
        return co.computeOnInit === undefined ? 0 : 1;
      }) as number[];
      const totalNumberOfInitComputes =
        computeCountResults.length === 0
          ? 0
          : computeCountResults.reduce((sum, num) => sum + num) ?? 0;

      //.reduce((sum, num) => sum + num) ?? 0;

      const end = performance.now();
      console.info(`Execution time: ${end - start} ms`);
      return { cols, data, rowKeys, totalNumberOfInitComputes };
    }
    return null;
  };

  const generateColumnExtensionFilters = (columns: BaseTableColumn[]) => {
    const result = columns
      .filter(
        (col) =>
          col._isInternal !== false && col.showColumnFilterExtension === true
      )
      .map((col) => {
        return COLUMN_EXTENSION_COMPARE_IDENTIFIER_ARRAY.map((extType) => {
          return {
            filterId: `${extType}_${col._columnIndex}`,
            filterName: extType,
            columnNames: [col.fieldName],
            defaultValue: "all",
            filterValue:
              extType !== COLUMN_EXTENSION_COMPARE_IDENTIFIER.COLUMN_EXT_BETWEEN
                ? null
                : ["", ""],
            columnIndexes: [col._columnIndex],
            isStatic: false,
            filterGroupId: `${FILTER_GROUP_ID}_${col._columnIndex}`,
            ignore: true,
            filterCompareIdentifier: extType ?? null,
          } as BaseGridFilterParameters;
        });
      })
      .flat();

    return result;
  };

  const setPostProcessConfiguration = (processResults: any) => {
    if (processResults !== null) {
      const columnFilterExtensions = generateColumnExtensionFilters(
        processResults?.cols ?? []
      );

      const updatedFilterParameters = [
        ...(instanceInternal?.filterParameters ?? []),
        ...columnFilterExtensions,
      ];

      setEntireInstance({
        BaseGridProperties: {
          ...instance,
          getURL: instance.getURL,
          columns: processResults?.cols ?? [],
          data: processResults?.data ?? [],
          columnsAndData: null,
          columnOptions: instance.columnOptions,
          optionsConfiguration: instance.optionsConfiguration,
          // orderByColumnIndex: 0,
          orderDirection: "asc",
          hasToggledEdit: false,
          changedData: [],
          filterOptions: instance?.filterOptions ?? [],
        },
        BaseGridInternalProperties: {
          ...instanceInternal,
          rowKeys: processResults.rowKeys,
          hasCellManagerSetMounted: false,
          inProcessCellManagerOperations: [],
          unprocessedMountedCellManagers: [],
          totalNumberOfInitComputes:
            processResults?.totalNumberOfInitComputes ?? 0,
          filterParameters: updatedFilterParameters ?? [],
          sortedAndFilteredData: [],
          isReadyToRender: true,
        },
      });
    }
  };

  const dispatchDataSourceGetURL = () => {
    const processResult = processColumnsAndData(
      responseGet?.responseData?.tableData?.columns ?? [],
      responseGet?.responseData?.tableData?.data ?? []
    );
    setPostProcessConfiguration(processResult);
  };

  const dispatchDataSourceColumnsAndData = () => {
    const processResult = processColumnsAndData(
      instance?.columnsAndData?.columns ?? [],
      instance?.columnsAndData?.data ?? []
    );
    setPostProcessConfiguration(processResult);
  };

  useEffect(() => {
    if (instanceInternal?.accessors?._reloadDataSources) {
      if (
        instance.getURL !== undefined &&
        instance.getURL !== null &&
        instance.getURL !== ""
      ) {
        // do data process for URL
        dispatchGet();
      }

      if (
        instance.columnsAndData !== undefined &&
        instance.columnsAndData !== null &&
        instance.columnsAndData.columns.length > 0
      ) {
        // do data process for manual columns and data

        dispatchDataSourceColumnsAndData();
      }
    }
  }, [
    instance.getURL,
    instance.columnsAndData,
    instanceInternal?.accessors?._reloadDataSources,
  ]);

  // useEffect(() => {
  //   if (
  //     instanceInternal?.accessors?._reloadDataSources &&
  //     instance.getURL !== undefined &&
  //     instance.getURL !== null &&
  //     instance.getURL !== ""
  //   ) {
  //     dispatchGet();
  //   }
  // }, [instance.getURL, instanceInternal?.accessors?._reloadDataSources]);

  useEffect(() => {
    if (
      responseGet.responseData !== undefined &&
      responseGet.responseData !== null
    ) {
      dispatchDataSourceGetURL();
    }
  }, [responseGet]);

  return null;
};

export default BaseGridDataProcessor;
