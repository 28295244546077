import { ErrorOutlineOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { SelectOptions } from "../../../dtos/select-options";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getDisplayNameByOptionId,
  getValueByOptionName,
} from "../../../utilities/selectFunctions";
import { Col, Font, Input, InputDate, Row, Select } from "../../TrueUI";
import { RowProps } from "../../TrueUI/Grids/Row";
import {
  claimIncidentStateProps,
  getValue,
  hasActivePolicy,
} from "../AddNewIncidentUtils";

type IncidentStartProps = {
  claimIncidentUI: claimIncidentStateProps;
  setClaimIncidentUI: (claimIncidentUI: claimIncidentStateProps) => void;
  textFieldVariant?: "filled" | "standard";
  labelsType?: KeysAsType<FontsType>;
  errorDetails?: any;
  readOnly?: boolean;
};

const rowProps: RowProps = {
  verticalAlign: "flex-start",
  horizontalAlign: "flex-start",
  horizontalMargin: "0px",
  horizontalGutter: "0px",
  verticalMargin: "0px",
  verticalGutter: "0px",
  allowWrap: true,
};

const IncidentStart: FC<IncidentStartProps> = ({
  claimIncidentUI,
  setClaimIncidentUI,
  textFieldVariant = "standard",
  //labelsType,
  errorDetails,
  readOnly,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const locations = getValue(claimIncidentUI?.locations);
  const claimIncident = getValue(claimIncidentUI?.claimIncident);
  const insured = getValue(claimIncidentUI?.insuredInfo);
  const hasPolicy = getValue(claimIncidentUI?.hasPolicy);
  const stateReport = getValue(claimIncidentUI?.stateReport);
  const stateReportId = claimIncidentUI?.claimIncident?.incident?.stateReportID;

  const locationTypes: Partial<SelectOptions>[] = locations
    ? locations.map((type) => ({
        displayName: type.description,
        intValue: type.addressId,
      }))
    : [];

  const jurisdictionTypes: Partial<SelectOptions>[] = locations
    ? locations
        .map((address) => ({
          //map states from locations
          displayName: address.state,
          intValue: address.stateId,
        }))
        .filter(
          //filter to remove duplicate states
          (state, i, arr) =>
            arr.findIndex((s) => s.intValue === state.intValue) === i
        )
    : [];

  const updateLocation = (addressId: number) => {
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          injuryAddressID: addressId,
        },
      },
    });
  };

  const updateDateInjury = (date: Date) => {
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          injuryDateTime: date,
        },
      },
      hasPolicy: hasActivePolicy(
        getValue(date),
        getValue(claimIncidentUI?.policies ?? [])
      ),
    });
  };

  const updateJurisdiction = (value: number) => {
    const incident = claimIncidentUI?.claimIncident?.incident;
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...incident,
          incidentID: incident?.incidentID,
          incidentStatus: incident?.incidentStatus,
          injuryDateTime: incident?.injuryDateTime,
          injuryAddressID: incident?.injuryAddressID,
          insuredID: incident?.insuredID,
          stateReportID: value,
        },
      },
      lastSavedSection: -1,
      jurisdiction: getDisplayNameByOptionId(value, jurisdictionTypes),
    });
  };

  const WarningMessage = ({ children }) => {
    return (
      <Box sx={{ ml: 2.5, mt: "15px" }}>
        <Font>
          <ErrorOutlineOutlined
            color="error"
            sx={{ mr: 0.5, verticalAlign: "bottom" }}
          />
          {children}
        </Font>
      </Box>
    );
  };

  useEffect(() => {
    updateDateInjury(
      new Date(claimIncident?.incident?.injuryDateTime ?? Date.now())
    );
  }, []);

  return insured ? (
    <Box sx={{ textAlign: "left" }}>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 6, xl: 6 }}>
          <Input
            id="id-incidentStart-insuredName"
            name="incidentStartInsuredName"
            readOnly={readOnly}
            label="Insured"
            variant={textFieldVariant}
            value={insured?.legalName ?? ""}
            maxLength={300}
            errorMessage={errorDetails?.legalName}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 6, xl: 6 }}>
          <Select
            id="incident-location"
            name="incidentStartInsuredLocation"
            readOnly={readOnly}
            label={"Location"}
            options={locationTypes ?? []}
            value={
              claimIncident?.incident?.injuryAddressID ??
              insured?.primaryAddressId ??
              null
            }
            variant={textFieldVariant}
            inputWidth={"100%"}
            optionsMaxHeight="400px"
            onChange={(value) => updateLocation(value)}
            errorMessage={
              locationTypes
                ? insured?.primaryAddressId
                  ? errorDetails?.injuryAddressID
                  : ["No Primary location"]
                : ["Warning: No locations for this insured"]
            }
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col
          breakpoints={{ xs: 12 }}
          horizontalAlign="flex-start"
          verticalAlign="center"
        >
          <Select
            id="incident-jurisdiction"
            name="incidentStartInsuredJurisdiction"
            readOnly={readOnly}
            label={"Jurisdiction"}
            options={jurisdictionTypes}
            variant={textFieldVariant}
            value={
              claimIncident?.incident?.stateReportID ??
              getValueByOptionName(
                claimIncidentUI?.jurisdiction,
                jurisdictionTypes
              )
            }
            onChange={(value) => updateJurisdiction(value)}
            errorMessage={errorDetails?.jurisdiction}
            labelFontType="BOLD_CAPTION"
            inputWidth={window.innerWidth < 700 ? "100%" : "100px"}
          />
          {!conditionHasValue(stateReportId) && !readOnly && (
            <WarningMessage>
              Warning: No state jurisdiction found for this location
            </WarningMessage>
          )}
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col
          breakpoints={{ xs: 12 }}
          horizontalAlign="flex-start"
          verticalAlign="center"
        >
          <InputDate
            id="id-incidentStart-dateOfInjury"
            name="incidentStartDateOfInjury"
            readOnly={readOnly}
            disableFuture
            label="Date of Injury"
            variant={textFieldVariant}
            value={
              new Date(claimIncident?.incident?.injuryDateTime ?? Date.now())
            }
            onChangeRawValue={(value) => {
              updateDateInjury(value);
            }}
            errorMessage={errorDetails?.injuryDateTime}
            inputWidth={window.innerWidth < 700 ? "100%" : "15%"}
            labelFontType="BOLD_CAPTION"
          />
          {!hasPolicy && !readOnly && (
            <WarningMessage>
              Warning: No active policy for this date of injury
            </WarningMessage>
          )}
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ xs: 12 }}>
          <Typography
            variant="body1"
            align="justify"
            color={readOnly ? theme?.textColor : "inherit"}
            dangerouslySetInnerHTML={{
              __html: stateReport?.stateReport?.startInstructions ?? "",
            }}
          />
        </Col>
      </Row>
    </Box>
  ) : (
    <Font>Loading...</Font>
  );
};

export default IncidentStart;
