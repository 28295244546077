import { FC } from "react";
import { BaseTable } from "../../../dtos/base-table";
import { ClaimFinancialTotalsDto } from "../../../dtos/claim-financial-totals-dto";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { ClaimFinancialTotalsCustomRow } from "./ClaimFinancialTotalsCustomRow";
import Paper from "../../TrueUI/Containers/Paper";

type ClaimFinancialProps = {
  financialTableData?: BaseTable;
  claimFinancialTotals?: ClaimFinancialTotalsDto;
};
const tableName = "claim-financial-table";
const ClaimFinancial: FC<ClaimFinancialProps> = ({
  financialTableData,
  claimFinancialTotals,
}) => {
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: financialTableData?.columns ?? [],
      data: financialTableData?.data ?? [],
    },
    columnOptions: [
      { fieldName: "Reserves", align: "right" },
      { fieldName: "Paid", align: "right" },
      { fieldName: "NetIncurred", align: "right" },
    ],
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
    },
    toolbarOptions: {
      hideToolbar: true,
    },
    footerOptions: {
      CustomFooterComponent: () =>
        ClaimFinancialTotalsCustomRow(claimFinancialTotals),
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <Paper
      title="Financial"
      margin="25px 0"
      collapse
      childrenContainerHeight="150px"
    >
      <div style={{ marginLeft: "20px", width: "70%" }}>
        {conditionHasValue(financialTableData) ? (
          <BaseTable2 name={tableName} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </Paper>
  );
};

export default ClaimFinancial;
