import { FormattingDate } from "./dateFunctions";

export const toLowerCaseKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.charAt(0).toLowerCase() + key.substring(1)] = obj[key];
    return acc;
  }, {});
export const toUpperCaseKeys = (obj) =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.charAt(0).toUpperCase() + key.substring(1)] = obj[key];
    return acc;
  }, {});
export const areObjectsEqual = (originalObject: any, compareObject: any) => {
  if (originalObject !== null && compareObject !== null) {
    if (JSON.stringify(originalObject) === JSON.stringify(compareObject)) {
      return true;
    }
  }
  return false;
};

export const replaceEmptyStringWithNullForPost = (
  obj: any,
  propNamesToKeepEmptyString?: string[]
) => {
  const objectWithNullInsteadOfEmptyString = JSON.parse(
    JSON.stringify(obj, function (key, value) {
      return value === "" && !propNamesToKeepEmptyString?.includes(key)
        ? null
        : value;
    })
  );
  return objectWithNullInsteadOfEmptyString;
};
export const mergeObjectsWithMatchingAttributes = (
  referenceObject,
  valuesObject
) => {
  const newObjectToReturn = {};
  const referenceKeys = Object.keys(referenceObject);
  referenceKeys?.forEach((key) => {
    if (valuesObject[key] !== undefined) {
      newObjectToReturn[key] = valuesObject[key];
    }
  });

  return newObjectToReturn;
};

export const castObjectValuesToString = (postResponseDto) => {
  Object.keys(postResponseDto).forEach((key) => {
    switch (typeof postResponseDto[key]) {
      case "number":
        return (postResponseDto[key] = postResponseDto[key].toString());
      default:
        if (postResponseDto[key] instanceof Date) {
          return (postResponseDto[key] = FormattingDate(postResponseDto[key]));
        }
        return ""; // Needs review, flawed on inception.
    }
  });
  return postResponseDto;
};

export const getObjectWithPropertiesFiltered = (
  originalObject,
  stringToMatch: string,
  notInclude?: boolean
) => {
  return Object.fromEntries(
    Object.entries(originalObject).filter(([key]) =>
      notInclude ? !key.includes(stringToMatch) : key.includes(stringToMatch)
    )
  );
};

export const removeObjectProperty = (obj, propertyToRemove) => {
  const { [propertyToRemove]: removedProperty, ...rest } = obj;
  return rest;
};
