import { atom, atomFamily, selectorFamily } from "recoil";
import {
  BaseGridCellManagerOperations,
  BaseGridCellManagerProperties,
  BaseGridInternalProperties,
  BaseGridlFilterColumnManager,
  BaseGridlFilterColumnManagerConfiguration,
  BaseGridProperties,
  CellKey,
  GridDataRow,
  RowKey,
} from "./BaseGridProperties";
import { BaseTableColumn } from "../../dtos/base-table-column";
import { getInstanceOfTypeOrProperty } from "../../types/InstanceOfTypeOrProperties";

export type BaseGridKeyNameMapProperty = {
  uiid: string;
  name: string;
  hasHookRegistered: "unregistered" | "registered";
  disableInitialize?: boolean;
};

export type BaseGridCellInstanceProperties = {
  rowKey: RowKey | null;
  row: GridDataRow;
  readyToRender?: boolean;
  isNextManagerToProcess?: boolean;
  hasCompletedOperations?: boolean;
  hasInitOnComputeTriggered?: boolean;
  hasOnChangeComputeCompleted?: boolean;
  isHidden: boolean;
  isEditable: boolean;
  hasAtomInitMounted: boolean;
};

type InterceptChangeAtomFamilyProperties = {
  rowKey: string | null;
  cellKey: CellKey | null;
  changedValue: string | null;
  columnName: string | null;
  columnIndex: number | null;
};

export const interceptChangeAtomFamily = atomFamily<
  InterceptChangeAtomFamilyProperties | null,
  string
>({
  key: "interceptChangeAtomFamily",
  default: null,
});

export const SelectedGridCellManagerInstanceAtom = atom<string | null>({
  // The key for this instance should just be the RowKey
  key: "SelectedGridCellManagerInstanceAtom",
  default: null,
});

export const SingleGridCellInstanceAtomFamily = atomFamily<
  {
    rowKey: RowKey | null;
    cellKey: CellKey | null;
    value: string;
    columnIndex: number | null;
  },
  string
>({
  key: "SingleGridCellInstanceAtomFamily",
  default: {
    rowKey: null,
    cellKey: null,
    value: "",
    columnIndex: null,
  },
});

export const ChangedSingleGridCellInstanceCellKeysAtom = atom<string[]>({
  key: "ChangedSingleGridCellInstanceCellKeysAtom",
  default: [],
});

export const defaultGridCellManagerInstanceAtomState: BaseGridCellInstanceProperties =
  {
    rowKey: null,
    row: [],
    readyToRender: false,
    isNextManagerToProcess: false,
    hasCompletedOperations: false,
    hasInitOnComputeTriggered: false,
    hasOnChangeComputeCompleted: true,
    isHidden: false,
    isEditable: false,
    hasAtomInitMounted: false,
  };

export const GridCellManagerInstanceAtomFamily = atomFamily<
  BaseGridCellInstanceProperties,
  RowKey
>({
  // The key for this instance should just be the RowKey
  key: "GridCellManagerInstanceAtomFamily",
  default: defaultGridCellManagerInstanceAtomState,
});

export const GridInstanceAtomFamily = atomFamily<
  {
    BaseGridProperties?: BaseGridProperties;
    BaseGridInternalProperties?: BaseGridInternalProperties;
    // isReadyToRender?: boolean;
  } | null,
  string
>({
  key: "GridInstanceAtomFamily",
  default: null,
});

///////////// Base Grid Filter Atoms
export const BaseGridFilterOptionsAtomFamily = atomFamily<any, string>({
  key: "BaseGridFilterOptionsFamily",
  default: null,
});

export const BaseGridFilterOptionsRenderedResultsAtomFamily = atomFamily<
  any[],
  string
>({
  key: "BaseGridFilterOptionsRenderedResultsAtomFamily",
  default: [],
});
////////////////////////////////////

export const columnsSelectorFamily = selectorFamily<BaseTableColumn[], string>({
  key: "columnsSelector",
  get:
    (uiid) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(uiid));
      return instance?.BaseGridProperties?.columns ?? [];
    },
});

export const scopedBaseGridCellManager = selectorFamily({
  key: "scopedBaseGridPropertiesSelector",
  get:
    (params: {
      rowKey: string;
      propertyName: keyof BaseGridCellInstanceProperties | undefined;
    }) =>
    ({ get }) => {
      const instance = get(GridCellManagerInstanceAtomFamily(params.rowKey));

      return getInstanceOfTypeOrProperty(
        instance,
        params.propertyName ?? undefined
      );
    },
});

function scopedProperties<K, T extends keyof K>(
  instance: K | null,
  propertyName?: T
) {
  return propertyName === undefined || propertyName === null
    ? instance
    : instance?.[propertyName ?? "NO_PROPERTY_FOUND"] ?? null;
}

// export const scopedBaseGridPropertiesSelector = selectorFamily({
//   key: "scopedBaseGridPropertiesSelector",
//   get:
//     <T extends keyof BaseGridProperties>(params: {
//       uiid: string;
//       propertyName: T;
//     }) =>
//     ({ get }) => {
//       const instance = get(GridInstanceAtomFamily(params.uiid));
//       const resolvedInstance = instance?.BaseGridProperties ?? null;
//       if (params.propertyName === undefined || params.propertyName === null) {
//         return resolvedInstance;
//       } else {
//         return (
//           resolvedInstance?.[params?.propertyName ?? "NO_PROPERTY_FOUND"] ??
//           null
//         );
//       }
//     },
// });

export const scopedBaseGridPropertiesInternalSelector2 = selectorFamily({
  key: "scopedBaseGridPropertiesSelector",
  get:
    (params: {
      uiid: string;
      propertyName: keyof BaseGridInternalProperties | undefined;
    }) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(params.uiid));

      return getInstanceOfTypeOrProperty(
        instance?.BaseGridInternalProperties as BaseGridInternalProperties,
        params.propertyName ?? undefined
      );
    },
});

export const scopedBaseGridPropertiesSelector2 = selectorFamily({
  key: "scopedBaseGridPropertiesSelector",
  get:
    (params: {
      uiid: string;
      propertyName: keyof BaseGridProperties | undefined;
    }) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(params.uiid));
      return getInstanceOfTypeOrProperty(
        instance?.BaseGridProperties as BaseGridProperties,
        params.propertyName ?? undefined
      );
    },
});

export const scopedBaseGridPropertiesInternalSelector = selectorFamily({
  key: "scopedBaseGridPropertiesSelector",
  get:
    <T extends keyof BaseGridInternalProperties>(params: {
      uiid: string;
      propertyName: T | undefined;
    }) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(params.uiid));

      const resolvedInstance = instance?.BaseGridInternalProperties ?? null;
      if (params.propertyName === undefined || params.propertyName === null) {
        return resolvedInstance as BaseGridInternalProperties;
      } else {
        return (
          resolvedInstance?.[params?.propertyName ?? "NO_PROPERTY_FOUND"] ??
          (null as BaseGridInternalProperties[T])
        );
      }
    },
});

export const scopedBaseGridInternalPropertiesSelector = selectorFamily({
  key: "scopedBaseGridInternalPropertiesSelector",
  get:
    <T extends keyof BaseGridInternalProperties>(x: {
      uiid: string;
      propertyName?: T;
    }) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(x.uiid));
      return scopedProperties(
        instance?.BaseGridInternalProperties ?? null,
        x.propertyName
      );
    },
});

export const scopedBaseGridPropertiesSetSelector = selectorFamily({
  key: "scopedBaseGridPropertiesSelector",
  get:
    <T extends keyof BaseGridProperties>(x: {
      uiid: string;
      propertyName: T;
    }) =>
    ({ get }) => {
      const instance = get(GridInstanceAtomFamily(x.uiid));
      return scopedProperties(
        instance?.BaseGridProperties ?? null,
        x.propertyName
      );
    },
});

export const NextCellManagerToProcessAtom = atom<BaseGridCellManagerOperations>(
  {
    key: "NextCellManagerToProcessAtom",
    default: { rowKey: null, hasCompletedOperations: false },
  }
);

export const BaseGridKeyNameMapAtom = atom<BaseGridKeyNameMapProperty[]>({
  key: "BaseGridKeyNameMapAtom",
  default: [],
});

export const BaseGridIsReadyTrackerAtomFamily = atomFamily<
  string | null,
  string
>({
  key: "BaseGridIsReadyTrackerAtomFamily",
  default: null,
});

export const GridCellInstanceAtomFamily = atomFamily<
  BaseGridCellManagerProperties,
  string
>({
  key: "gridCellInstanceAtomFamily",
  default: {
    rowKey: null,
    isEditable: false,
    cells: [],
  },
});

export const BaseGridlFilterColumnManagerConfigurationAtom =
  atom<BaseGridlFilterColumnManagerConfiguration>({
    key: "BaseGridlFilterColumnManagerConfigurationAtom",
    default: {
      activelyOpenFilter: null,
      resetAllFilters: false,
    },
  });

export const BaseGridlFilterColumnManagerAtomFamily = atomFamily<
  BaseGridlFilterColumnManager,
  string
>({
  key: "BaseGridlFilterColumnAtomFamily",
  default: {
    isOpen: false,
    filtersApplied: false,
    removeEmptyEntires: false,

    // These properties are really only for the UI, just so the user can see what they typed once the manager instance is rebuilt. These really have no value outside of helping the user see what they put.
    betweenStartValue: null,
    betweenEndValue: null,
    greaterThanValue: null,
    lessThanValue: null,
    containsValue: null,
    equalsValue: null,
    beginsWith: null,
    endsWith: null,
  },
});
