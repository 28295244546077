import { Box, SxProps, Theme } from "@mui/material";
import { FC } from "react";
import { usePermissions } from "../../../hooks";
import NumberFormatCustom from "./InputCurrency";
import InputMaskSupport from "./InputMaskSupport";
// import "./Input.css";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import InternalDatePicker from "./InternalDatePicker";
import Font from "../Typography/Font";
import InternalTextArea from "./InternalTextArea";
import { inputStyles } from "./InputStyles";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { SearchIcon } from "../Icons/CustomIcons";
import { getVariant, validatePastedFileName } from "./InputUtil";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import "./input.module.css";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

export type InputProps = {
  id: string;
  name: string;
  trueElement?: string;
  label?: string;
  errorMessage?: string[] | null;
  value?: any | null;
  className?: string;
  readOnly?: boolean;
  focus?: boolean;
  variant?: "filled" | "standard" | "outlined";
  labelPosition?: "start" | "end" | "top" | "bottom";
  helperText?: string;
  placeholder?: string;
  type?:
    | "text"
    | "number"
    | "riskid"
    | "email"
    | "password"
    | "currency"
    | "fixedCurrency"
    | "postalCode"
    | "stringNumber"
    | "stringDecimal"
    | "search"
    //Support reasons. (To implement this type of inputs please use their own component)
    | "internalPhone"
    | "internalPhoneExt"
    | "internalDateTime"
    | "internalDate";
  maxLength?: number;
  minNumericValue?: number;
  maxNumericValue?: number;
  thousandSeparator?: boolean;
  fileNameValidation?: boolean;
  inputWidth?: string;
  permissions?: PermissionsEnums[];
  sx?: SxProps<Theme>;
  multiline?: boolean;
  resize?: boolean;
  rows?: number;
  decimalScale?: number;
  integerScale?: number;
  allowNegatives?: boolean;
  prefix?: string;
  suffix?: string;
  align?: "left" | "center" | "right";
  labelAlign?: "start" | "center" | "end";
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  isInputIdent?: boolean;
  onMount?: (e?: any) => void;
  onChangeRawValue?: (e?: any) => void;
  onChange?: (e?: any) => void;
  onKeyUp?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  onClick?: (e?: any) => void;
  onMouseDown?: (e?: any) => void;
  onMouseEnter?: (e?: any) => void;
  onMouseLeave?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;
  // Support reasons, to implement the date input use its own component.
  formatDate?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  tabIndex?: number;
  isAutoComplete?: boolean;
  stringNumberMask?: string;
  displayNumericArrows?: boolean;
  unmask?: boolean;
  htmlLabel?: string;
};

const Input: FC<InputProps> = ({
  variant,
  id,
  name,
  trueElement,
  errorMessage = null,
  label = null,
  readOnly = false,
  focus = false,
  value = "",
  labelPosition = "top",
  helperText = "",
  className = "",
  maxLength,
  minNumericValue,
  maxNumericValue,
  thousandSeparator,
  fileNameValidation,
  inputWidth = "100%",
  permissions, // TODO: @elara Update until Antonio's PR will be ready
  type = "text",
  placeholder,
  multiline,
  resize,
  rows = 2,
  formatDate,
  disableFuture,
  disablePast,
  decimalScale = 2,
  prefix,
  suffix,
  allowNegatives,
  align = "left",
  labelAlign = "start",
  inputFontType = "BODY",
  labelFontType = "BOLD_CAPTION",
  isInputIdent = true,
  onMount,
  onChange,
  onChangeRawValue,
  onKeyUp,
  onBlur,
  onClick,
  onMouseDown,
  onMouseLeave,
  onMouseEnter,
  onKeyDown,
  tabIndex = 0,
  isAutoComplete = true,
  stringNumberMask,
  displayNumericArrows = false,
  integerScale = 10,
  unmask = true,
  htmlLabel,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const hasPermission = usePermissions(permissions ?? []).hasPermission;
  const normalInputs = ["text", "email", "password", "search", "postalCode"];
  const currencyInputs = ["number", "currency", "fixedCurrency"];

  const isTextField = normalInputs.includes(type);
  const isCurrency = currencyInputs.includes(type);
  const isDate = type === "internalDate" || type === "internalDateTime";
  const isDisabled = hasPermission ? readOnly : true;
  const dangerKeysOnInputNumber = [69, 187, 188, 189, 190];
  const reservedKeysOnFileNameValidation = [
    "*",
    "/",
    "\\",
    "?",
    ".",
    "<",
    ">",
    ":",
    "|",
    '"',
  ];

  const _onInput = (e) => {
    const value = e.target.value;
    const indexOfDecimalPoint = value.indexOf(".");
    if (
      maxLength &&
      isCurrency &&
      indexOfDecimalPoint > -1 &&
      value.split(".")?.[0]?.length > maxLength - decimalScale - 1
    ) {
      value.split(".")?.[1]?.length === 0
        ? (e.target.value = value.slice(0, maxLength - decimalScale - 1))
        : (e.target.value = `${(e.target.value = value.slice(
            0,
            maxLength - decimalScale - 1
          ))}.${value.slice(
            indexOfDecimalPoint,
            maxLength + decimalScale + 1
          )}`);
    } else {
      if (type === "number" && maxLength && value.length > maxLength) {
        e.target.value = value.slice(0, maxLength);
      }
      if (isCurrency && maxLength && value.length > maxLength) {
        if (value.includes(".")) {
          e.target.value =
            value.slice(0, maxLength) +
            value.slice(indexOfDecimalPoint, maxLength + decimalScale + 1);
        } else e.target.value = value.slice(0, maxLength);
      }
    }
  };

  const _onChange = (e) => {
    if (!isCurrency) e.target.value = e.target.value.trimStart();
    onChangeRawValue?.(e.target.value !== "" ? e.target.value : null);
    onChange?.(e);
  };

  const _onKeyDown = (e) => {
    onKeyDown?.(e);

    if (type === "number" && dangerKeysOnInputNumber.includes(e.keyCode)) {
      e.preventDefault();
    } else if (
      fileNameValidation &&
      reservedKeysOnFileNameValidation.includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const _onPaste = (e) => {
    if (type === "number" && e.clipboardData.getData("text") === "e") {
      e.preventDefault();
    } else if (fileNameValidation) {
      const pastedValue = e.clipboardData.getData("text") as string;
      const isValidFileName = validatePastedFileName(
        pastedValue,
        reservedKeysOnFileNameValidation
      );

      if (!isValidFileName) e.preventDefault();
    }
  };

  const inputProps = {
    type: type === "search" ? "text" : type,
    id: id,
    name: name,
    disabled: isDisabled,
    autoFocus: focus,
    maxLength: maxLength,
    max: maxNumericValue,
    min: minNumericValue,
    onMount: onMount,
    onKeyUp: onKeyUp,
    onBlur: onBlur,
    onClick: onClick,
    onMouseDown: onMouseDown,
    onInput: _onInput,
    onChange: _onChange,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    onKeyDown: _onKeyDown,
    placeholder: placeholder,
    onPaste: _onPaste,
    rows: rows,
    style: {
      textAlign: align,
      fontSize: theme?.[inputFontType]?.size,
      fontWeight: theme?.[inputFontType]?.weight,
    },
    className: TABBABLE_CLASS_NAME,
    "true-element": trueElement ? trueElement : `true-input-${name}`,
    stringNumberMask: stringNumberMask,
  };

  const inputSelector = () => {
    if (isCurrency) {
      return (
        <NumberFormatCustom
          {...inputProps}
          decimals={decimalScale}
          prefix={type !== "number" ? prefix : ""}
          suffix={suffix}
          negatives={allowNegatives}
          maxValue={maxNumericValue}
          minValue={minNumericValue}
          thousandSeparator={thousandSeparator}
          value={value}
          tabIndex={tabIndex}
          displayNumericArrows={displayNumericArrows}
          onBlur={(e) => {
            inputProps.onBlur?.(e);
          }}
        />
      );
    }
    if (multiline) {
      return (
        <InternalTextArea
          {...inputProps}
          value={value !== "" ? value : ""}
          tabIndex={tabIndex}
        />
      );
    }
    if (isDate) {
      return (
        <InternalDatePicker
          type={type}
          formatDate={formatDate}
          value={value !== "" ? value : null}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onChange={onChange}
          disabled={readOnly}
          trueElement={`true-input-${name}`}
          maxValue={maxNumericValue}
          minValue={minNumericValue}
          tabIndex={tabIndex}
        />
      );
    }
    if (isTextField) {
      return (
        <>
          <input
            {...inputProps}
            onBlur={(e) => {
              inputProps.onBlur?.(e);
            }}
            value={value !== "" ? value : ""}
            tabIndex={tabIndex}
            autoComplete={isAutoComplete ? "on" : "off"}
          />
        </>
      );
    }
    return (
      <InputMaskSupport
        {...inputProps}
        value={value !== "" ? value : ""}
        tabIndex={tabIndex}
        isAutoComplete={isAutoComplete ?? true}
        decimals={decimalScale}
        integerScale={integerScale}
        unmask={unmask}
        allowNegatives={allowNegatives}
      />
    );
  };

  const addInputIdent = () =>
    labelPosition === "top" && !isEmptyValue(label) && isInputIdent;

  return (
    <Box
      className={`true_input_general_container ${
        addInputIdent() ? "ident" : ""
      }`}
      style={{ width: inputWidth }}
      //true_input_general_container ident MuiBox-root css-xa7vy2
      //true_input_general_container ident MuiBox-root css-0
      sx={inputStyles(theme, addInputIdent())}
    >
      <div
        className={`true_input_container ${
          isDisabled ? "read-only" : ""
        } ${labelPosition} ${type === "search" ? "search" : ""}`}
        title={readOnly ? value : ""}
      >
        {(label != null || htmlLabel !== null) && (
          <>
            <Font
              className={`true_input_label ${getVariant(readOnly, variant)} ${
                labelFontType === undefined ? "withoutSize" : ""
              }`}
              fontType={labelFontType}
              textAlign={labelAlign}
              htmlText={htmlLabel}
            >
              {label !== "" ? label : <>&nbsp;</>}
            </Font>
          </>
        )}
        <div
          className={`true_input ${getVariant(readOnly, variant)} ${
            errorMessage != null ? "true_input_error" : ""
          } ${isDisabled ? "read-only" : ""} ${className} ${
            localOptions?.theme?.defaultTheme
          } ${multiline ? "multiline" : ""} ${
            isDate ? "true_input_date" : ""
          } ${resize ? "resize" : ""} `}
        >
          {inputSelector()}
        </div>
        {errorMessage != null && (
          <span className="true_input_error_txt">
            {errorMessage?.join(" ")}
          </span>
        )}
        {type === "search" && <SearchIcon fontSize="small" />}
      </div>
      {helperText && (
        <span className="true_input_helper_text">{helperText}</span>
      )}
    </Box>
  );
};

export default Input;
