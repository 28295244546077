import { Link as MuiLink, FormControlLabel } from "@mui/material";
import { FC } from "react";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import Font from "./Font";

type linkProps = {
  id?: string;
  name?: string;
  label?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  isLabelInline?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  displayValue?: string | JSX.Element;
  variant?: "filled" | "standard" | "outlined";
  containerWidth?: string;
  linkFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  display?: "inline" | "block" | "inline-block" | "flex" | "none";
  textAlign?: "start" | "center" | "end";
  icon?: string;
  primaryFontColor?: boolean;
  secondaryFont?: boolean;
  underline?: "none" | "hover" | "always";
  truncate?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => void;
};

const Link: FC<linkProps> = ({
  id = "",
  name = "",
  href = "",
  label = "",
  labelPosition = null,
  target = "",
  rel = "",
  displayValue = "",
  containerWidth = "100%",
  linkFontType = "TITLE",
  labelFontType = "BOLD_BODY",
  display = "inline",
  icon,
  primaryFontColor = true,
  secondaryFont,
  underline = "none",
  textAlign,
  truncate,
  disabled = false,
  onClick,
}) => {
  const baseLink = (
    <MuiLink
      id={id}
      true-element={`true-input-link-${name}`}
      href={disabled || href === "" ? undefined : href}
      target={target}
      rel={rel}
      onClick={(e) => {
        if (!disabled && onClick) {
          onClick(e);
        }
      }}
      underline={disabled ? "none" : underline}
      sx={{
        cursor: disabled ? "default" : "pointer",
      }}
    >
      {conditionHasValue(icon) && (
        <span
          className={`k-icon k-i-${icon}`}
          style={{ marginRight: "5px" }}
        ></span>
      )}
      <Font
        fontType={linkFontType}
        primaryColor={disabled ? false : primaryFontColor}
        secondaryFont={secondaryFont}
        opacity={disabled ? 60 : 100}
        textAlign={textAlign}
        display={display}
        truncate={truncate}
      >
        {displayValue}
      </Font>
    </MuiLink>
  );

  return (
    <div
      className="true_link_container"
      style={{ width: containerWidth, height: "100%", display: display }}
    >
      <FormControlLabel
        className={`${
          labelPosition === undefined || labelPosition === null
            ? "true_hide_form_label"
            : "true_label"
        } `}
        sx={{
          width: "100%",
          alignItems: "center",
          margin: "0px !important",
          justifyContent: "flex-end",
          height: "32px",
          "& > .MuiFormControlLabel-root": {
            display: "inline-flex",
          },
          "& > .MuiTypography-root": {
            fontSize: "unset",
            lineHeight: "unset",
          },
          "& > .MuiLink-root": {
            alignSelf: "flex-start",
            marginLeft: `${labelPosition === "top" ? "12px" : ""}`,
          },
          "& > .MuiFormControlLabel-label": {
            alignSelf: "flex-start",
          },
          display: `${label !== "" ? "inline-flex" : "inline"}`,
        }}
        label={<Font fontType={labelFontType}>{label}</Font>}
        labelPlacement={(labelPosition as any) ?? "start"}
        control={baseLink}
        disabled={disabled}
      />
    </div>
  );
};

export default Link;
