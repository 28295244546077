import { useRecoilValue } from "recoil";
import { usePermissions } from "../../hooks";
import { Col, Font, Heading3, Row } from "../TrueUI";
import InlineLink from "../TrueUI/Links/InlineLink";
import { globalOptions } from "../../GlobalAtoms";
import { PermissionsEnums } from "../../dtos/permissions-enums";

const WelcomeText = () => {
  const localOptions = useRecoilValue(globalOptions);
  const userName = localOptions?.initOptions?.shortName;
  const hasClaimCoveragePermission = usePermissions([
    PermissionsEnums.PORTAL_POLICY,
    PermissionsEnums.PORTAL_CLAIMS,
    PermissionsEnums.PORTAL_INCIDENT,
  ]).hasPermission;
  const hasPortalBillPay =
    localOptions?.initOptions?.programInfo?.portalHasBillPay;

  const billPayComponent = (
    <>
      {" and pay your "}
      <InlineLink permission={true} href={"/billing"}>
        bill
      </InlineLink>
      {" online"}
    </>
  );
  return (
    <Row horizontalMargin="0px" horizontalGutter="0px">
      <Col breakpoints={{ sm: 12, md: 6, lg: 6, xl: 6 }} displayFlex={false}>
        <Heading3 textAlign="start" display="block" whiteSpace="normal">
          Welcome, {userName}!
        </Heading3>
        <Font
          secondaryFont
          textAlign={"start"}
          whiteSpace="normal"
          fontType="TITLE"
          display="block"
        >
          {"The policyholder portal is an easy-to-use tool to manage your "}
          <InlineLink permission={hasClaimCoveragePermission}>
            coverage
          </InlineLink>
          {", submit and review "}
          <InlineLink permission={hasClaimCoveragePermission} href={"/claims"}>
            claims
          </InlineLink>
          {hasPortalBillPay ? billPayComponent : null}
          {". Need to send us something? Click "}
          <InlineLink permission={true} href={"/upload-files"}>
            here
          </InlineLink>
          {" to upload any documents."}
        </Font>
      </Col>
    </Row>
  );
};

export default WelcomeText;
