import { FC, useEffect, useState } from "react";
import { PortalDocCategoryDto } from "../../dtos/portal-doc-category-dto";
import { useApiGet } from "../../hooks";
import { PortalDocumentDto } from "../../dtos/portal-document-dto";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import ListDocument from "../TrueUI/Documents/ListDocument";
import { useRecoilState } from "recoil";
import { refreshDocumentsAtom } from "./DocumentAtoms";

type ListDocumentContainerProps = {
  category?: PortalDocCategoryDto | null;
};

const ListDocumentContainer: FC<ListDocumentContainerProps> = ({
  category,
}) => {
  const [documents, setDocuments] = useState<PortalDocumentDto[]>([]);

  const [refreshDocuments, setRefreshDocuments] =
    useRecoilState(refreshDocumentsAtom);

  const { responseGet: responseDocuments, dispatchGet: dispatchGetDocuments } =
    useApiGet<PortalDocumentDto[]>(
      `api/Documents/GetPortalDocuments?portalArea=${
        category?.portalArea
      }&portalDocSection=${category?.section}&portalDocCategory=${
        category?.portalDocCategoryID ?? -1
      }&categoryType=${category?.categoryType ?? -1}`
    );

  useEffect(() => {
    if (refreshDocuments) {
      dispatchGetDocuments();
      setRefreshDocuments(false);
    }
  }, [refreshDocuments]);

  useEffect(() => {
    dispatchGetDocuments();
  }, [category]);

  useEffect(() => {
    if (isAPITotallyComplete(responseDocuments)) {
      setDocuments(responseDocuments?.axiosResponse?.data ?? []);
    }
  }, [responseDocuments]);

  return (
    <ListDocument
      documents={documents}
      section={category?.section}
      onSaveFavorite={() => setRefreshDocuments(true)}
    />
  );
};
export default ListDocumentContainer;
