import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";

export const inputStyles = (theme: Theme, addInputIdent: boolean = false) => ({
  "& .true_input_container": {
    display: "flex",
    alignItems: "center",
    "&.search": {
      position: "relative",
      display: "flex !important",
      alignItems: "center",
      svg: {
        color: theme?.primary,
        fontSize: theme?.title.size,
        position: "absolute",
        right: 0,
        mt: "15px",
      },
    },
    "&.start": {
      "& .true_input_label": {
        marginRight: "5px",
      },
    },
    "&.top": {
      display: "block",
    },
    "&.end": {
      flexDirection: "row-reverse",
      "& .true_input_label": {
        marginLeft: "5px",
      },
    },
    "&.bottom": {
      flexDirection: "column-reverse",
      "& .true_input_label": {
        marginTop: "5px",
      },
    },
    "& .true_input": {
      "&true_input_with_icon": {
        paddingRight: "30px",
      },
      "&.standard": {
        height: "32px",
        position: "relative",
        "& input, & textarea": {
          padding: "4px 0 5px",
        },
        "&:not(.true_input_error):hover:before": {
          borderBottom: `2px solid ${theme?.borderColor}`,
        },
      },
      "&.filled": {
        height: "31px",
        position: "relative",
        cursor: "text",
        backgroundColor: theme?.secondary,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        transition: "background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        "& input": {
          color: theme?.inputTextColor,
        },
        "& .k-button-solid-base": {
          backgroundColor: "transparent",
        },
        "& input, & textarea": {
          padding: "4px 12px",
        },
        "&.read-only": {
          backgroundColor: "transparent",
          "& input": {
            color: theme?.textColor,
          },
        },
        "&:not(.read-only):hover": {
          background: getColorWithOpacityHexaDecimal(theme?.secondary, 60),
        },
        "&:not(.true_input_error):hover:before": {
          borderBottom: `2px solid ${theme?.borderColor}`,
        },
      },
      "&.outlined": {
        height: "40px",
        position: "relative",
        borderRadius: "4px",
        "& input, & textarea": {
          padding: "8.5px 14px",
          borderRadius: "4px",
        },
        "& .k-datepicker, & .k-datetimepicker": {
          padding: "8.5px 0",
          borderRadius: "4px",
          border: `1px solid ${theme?.borderColor}`,
          display: "flex",
          alignItems: "center",
          "&:hover": {
            border: `1px solid ${theme?.borderColor}`,
          },
          "&:focus-within": {
            border: `2px solid ${theme?.primary}`,
          },
        },
        "&:not(.true_input_date) input, &:not(.true_input_date) textarea": {
          border: `1px solid ${theme?.borderColor}`,
        },
        "&.true_input_error input, &.true_input_error textarea": {
          border: `1px solid ${theme?.danger}`,
        },
        "&.true_input_error:focus-within input, &.true_input_error:focus-within textarea":
          {
            border: `1px solid ${theme?.danger}`,
          },
        "&:not(.true_input_date):not(.read-only):focus-within input, &:not(.true_input_date):not(.read-only):focus-within textarea":
          {
            border: `2px solid ${theme?.primary}`,
          },
      },
      "&.multiline": {
        height: "auto",
      },
      "&.resize": {
        resize: "both",
        overflow: "hidden",
        textarea: {
          height: "100% !important",
        },
      },
      "&.read-only": {
        "& textarea": {
          background: "transparent",
        },
        "& textarea, & input": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
          display: "-webkit-box !important",
          pointerEvents: "none",
          paddingLeft: addInputIdent ? "12px" : "0px",
        },
        "&:before": {
          display: "none",
        },
        "&:hover": {
          cursor: "default",
        },
      },
      "&.true_input_error": {
        "&:after": {
          borderBottom: `2px solid ${theme?.danger}`,
          transform: "scaleX(1)",
        },
        "&:before": {
          borderBottom: `1px solid ${theme?.danger}!important`,
          transform: "scaleX(1)",
        },
      },
      "& textarea": {
        resize: "none",
        background: "#fff",
      },
      "& input, & textarea": {
        height: "100%",
        font: "inherit",
        fontSize: "var(--default-font-size-input)",
        letterSpacing: "inherit",
        color: theme?.textColor,
        border: 0,
        background: "none",
        margin: 0,
        display: "block",
        width: "100%",
        "&:focus": {
          outline: "none",
        },
      },
      "& .k-datepicker, & .k-datetimepicker": {
        backgroundColor: "transparent",
      },
      "& .k-input": {
        borderColor: "transparent",
        fontSize: "var(--default-font-size-input)",
        "&:focus-within": {
          boxShadow: "none",
        },
      },
      "& .k-button-solid-base": {
        width: "28px",
        height: "28px",
        borderRadius: "20px",
      },
      "&:not(.true_input_date) .k-input:focus-within": {
        border: "none",
      },
      "&:not(.outlined)": {
        "&::after": {
          borderBottom: `2px solid ${theme?.primary}`,
          left: "0",
          bottom: "0",
          content: "''",
          position: "absolute",
          right: "0",
          transform: "scaleX(0)",
          transition: "transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          WebkitTransform: "scaleX(0)",
          MozTransform: "scaleX(0)",
          msTransform: "scaleX(0)",
          WebkitTransition:
            "-webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          pointerEvents: "none",
        },
        "&::before": {
          borderBottom: `1px solid ${theme?.borderColor}`,
          left: 0,
          bottom: 0,
          content: "''",
          position: "absolute",
          right: 0,
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          WebkitTransition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          pointerEvents: "none",
        },
        "&:not(.true_input_error):not(.read-only):focus-within:after": {
          transform: "scaleX(1) translateX(0)",
        },
      },
      "&.bottom-border": {
        borderBottom: `1px solid ${theme?.borderColor}`,
      },
    },
    "& .true_input_label": {
      display: "block",
      color: theme?.textColor,
      "&.withoutSize": {
        transform: "none",
        //lineHeight: "var(--default-line-height)",
      },
    },
    "& .true_input, & .true_select": {
      width: "100%",
    },
    "& span.true_input_error_txt:not(.k-datepicker):not(.k-datetimepicker)": {
      color: theme?.danger,
      fontSize: theme?.smallTitle?.size,
      //lineHeight: "1.66",
      letterSpacing: "0.03333em",
      textAlign: "left",
      marginTop: "4px",
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      display: "block",
    },
  },
  "& .true_input_helper_text": {
    color: theme?.textColor,
    opacity: 0,
    display: "block",
    fontWeight: theme?.boldCaption?.weight,
    fontSize: theme?.boldCaption?.size,
    //lineHeight: "1.66",
    letterSpacing: "0.03333em",
    textAlign: "left",
    marginTop: "3px",
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
  },
  "& .k-calendar": {
    zIndex: 1000,
    "& .k-nav-today, & .k-calendar-view .k-today, & .k-calendar-yearview .k-today, & .k-calendar-navigation li:hover, & .k-calendar-navigation li.k-state-hover":
      {
        color: theme?.primary,
      },
    "& .k-nav-today:hover": {
      color: getColorWithOpacityHexaDecimal(theme?.primary, 60),
    },
    "& .k-calendar-td.k-state-selected .k-link, & .k-calendar-td.k-state-selected .k-link:hover":
      {
        backgroundColor: theme?.primary,
      },
  },

  "&:hover .true_input_helper_text": {
    opacity: 1,
  },
  "& .k-disabled": {
    opacity: "1 !important",
  },
});

export const inputTaxStyles = (theme: Theme, readOnly: boolean) => ({
  display: "flex",
  width: "100%",
  ".true_input_general_container": {
    width: readOnly ? "fit-content !important" : "100%",
    ".true_input_container.read-only": {
      width: "fit-content !important",
      ".true_input_label": {
        width: readOnly ? "fit-content !important" : "100%",
      },
      ".true_input.read-only": {
        width: "fit-content !important",
        input: {
          width: readOnly ? "100px !important" : "100%",
        },
      },
    },
  },
  "& .true_icons_container": {
    display: "flex",
    alignItems: "flex-end",
    "& svg": {
      width: "16px",
      height: "16px",
      color: theme?.textColor,
    },
  },
  "& .true_icons_container >button": {
    transform: "translateY(-3px)",
  },
});

export const inputWithIconStyles = (iconColor?: string) => ({
  inputWarningIconWrapper: {
    display: "flex",
    width: "100%",
  },

  inputWarningIcon: {
    alignSelf: "flex-end",
    marginLeft: "-30px",
    zIndex: 1,
    color: iconColor ?? "",
  },
});
