import { FC, ReactNode, isValidElement } from "react";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import Font from "../Typography/Font";
import Collapse from "../Collapse/Collapse";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import style from "./paper.module.css";

type PaperProperties = {
  children: any;
  title?: string | ReactNode;
  titleFontType?: KeysAsType<FontsType>;
  margin?: string;
  padding?: string;
  overflowX?: "visible" | "hidden" | "clip" | "scroll" | "auto";
  collapse?: boolean;
  collapseExpanded?: boolean;
  fullHeight?: boolean;
  childrenContainerHeight?: string;
};
const Paper: FC<PaperProperties> = ({
  children,
  titleFontType = "HEADING_6",
  title,
  margin = "0",
  padding = "10px 10px 10px 25px",
  collapse = false,
  collapseExpanded = true,
  fullHeight = false,
  overflowX = "auto",
  childrenContainerHeight = "auto",
}) => {
  const _title = isValidElement(title) ? (
    title
  ) : (
    <Font fontType={titleFontType} display="block" textAlign="start">
      {title}
    </Font>
  );

  return collapse ? (
    <Collapse
      shadowContainer
      title={_title}
      expanded={collapseExpanded}
      content={
        <div
          className={style.collapse_children_container}
          style={{
            paddingLeft: "15px",
            overflow: "auto",
            paddingTop: "5px",
            height: childrenContainerHeight,
          }}
        >
          {children}
        </div>
      }
      titleHeightAuto
    />
  ) : (
    <div
      style={{
        overflowX: overflowX,
        width: "100%",
        background: "#fff",
        padding: "5px",
        margin: margin,
        borderRadius: "3px",
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        flexGrow: fullHeight ? 1 : 0,
        marginBottom: fullHeight ? "0" : "5px",
      }}
    >
      {conditionHasValue(title) && (
        <div style={{ padding: "0px 16px", margin: "5px 0" }}>{_title}</div>
      )}
      <div
        style={{
          padding: padding,
          textAlign: "left",
          height: childrenContainerHeight,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Paper;
