import RouteError from "./RouteError";
import Dashboard from "./components/Dashboard/Dashboard";
import Incident from "./components/Incident/Incident";
import MainClaims from "./components/Claims/MainClaims";
import Emma from "./components/SandBox/Emma";
import Documents from "./components/Documents/Documents";
import { PortalAreaEnum } from "./dtos/portal-area-enum";
import ClaimDetail from "./components/Claims/ClaimDetail";
import UploadFilesForm from "./components/UploadFilesForm/UploadFilesForm";
import Billing from "./components/Billing/Billing";
import Mafer from "./components/SandBox/Mafer";
import PayrollReport from "./components/Billing/PayrollReport/PayrollReport";
import Policy from "./components/Policy/Policy";
import PaymentRedirectPostBack from "./components/Billing/PayrollReport/CreditCardPaymentRedirect/PaymentRedirectPostBack";
import AuthenticatedRoutes from "./components/Security/AuthenticatedRoutes";
import { PermissionsEnums } from "./dtos/permissions-enums";

const ENV_LOCAL = "local";
const ENV_DEV = "dev";
const ENV_QA = "qa";

const AppRoutes = (
  <AuthenticatedRoutes
    defaultPermissions={[PermissionsEnums.PORTAL_INSURED_ADMIN]}
    routesOptions={[
      {
        path: "/",
        element: <Dashboard />,
        noAuth: true,
      },
      {
        path: "/home",
        element: <Dashboard />,
        noAuth: true,
      },
      {
        path: "/claims",
        element: <MainClaims />,
        permissions: [
          PermissionsEnums.PORTAL_CLAIMS,
          PermissionsEnums.PORTAL_INCIDENT,
        ],
      },
      {
        path: "/claims/:claimId",
        element: <ClaimDetail />,
        permissions: [
          PermissionsEnums.PORTAL_CLAIMS,
          PermissionsEnums.PORTAL_INCIDENT,
        ],
      },
      {
        path: "*",
        element: <RouteError />,
        noAuth: true,
      },
      {
        path: "/incident",
        element: <Incident />,
        permissions: [
          PermissionsEnums.PORTAL_CLAIMS,
          PermissionsEnums.PORTAL_INCIDENT,
        ],
      },
      {
        path: "/documents-and-forms",
        element: <Documents portalArea={PortalAreaEnum?.GENERAL_DOCUMENTS} />,
        noAuth: true,
      },
      {
        path: "/safety-and-loss-control",
        element: (
          <Documents portalArea={PortalAreaEnum?.LOSS_CONTROL_DOCUMENTS} />
        ),
        permissions: [PermissionsEnums.PORTAL_LOSS_CONTROL],
      },
      {
        path: "/upload-files",
        element: <UploadFilesForm />,
        noAuth: true,
      },
      {
        path: "/billing",
        element: <Billing />,
        permissions: [PermissionsEnums.PORTAL_BILLING],
      },
      {
        path: "/payroll-report/:payrollReportId",
        element: <PayrollReport />,
        noAuth: true,
      },
      {
        path: "/payment-redirect",
        element: <PaymentRedirectPostBack />,
        noAuth: true,
      },
      {
        path: "/policy",
        element: <Policy />,
        permissions: [PermissionsEnums.PORTAL_POLICY],
      },
      {
        path: "/emma",
        element: <Emma />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
      {
        path: "/mafer",
        element: <Mafer />,
        allowedEnvironments: [ENV_LOCAL, ENV_DEV, ENV_QA],
      },
    ]}
  />
);

export default AppRoutes;
