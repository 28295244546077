import { FC, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { StateReportPage } from "../../../dtos/state-report-page";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import {
  Row,
  Col,
  InputPhone,
  InputDate,
  Font,
  Input,
  Upload,
  Switch,
} from "../../TrueUI";
import { RowProps } from "../../TrueUI/Grids/Row";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { GettingDateWithoutTime } from "../../../utilities/dateFunctions";
import styles from "../IncidentStyles.module.css";
import { ClaimIncidentDto } from "../../../dtos/claim-incident-dto";

type IncidentFinishProps = {
  hasSignature?: boolean;
  setSignature?: any;
  stateReport?: StateReportPage;
  claimIncident?: ClaimIncidentDto;
  setClaimIncident?: any;
  textFieldVariant?: "outlined" | "filled" | "standard";
  labelsType?: KeysAsType<FontsType>;
  errorDetails?: any;
  readOnly?: boolean;
};

const IncidentFinish: FC<IncidentFinishProps> = ({
  hasSignature,
  setSignature,
  stateReport,
  claimIncident,
  setClaimIncident,
  textFieldVariant = "standard",
  //labelsType,
  errorDetails,
  readOnly,
}) => {
  const [files, setFiles] = useState<{ [key: string]: number } | undefined>(
    claimIncident?.files
  );

  useEffect(() => {
    setClaimIncident((prevState) => ({
      ...prevState,
      claimIncident: {
        ...prevState.claimIncident,
        files: files,
      },
    }));
  }, [files]);

  const updateValue = (field: string, value: string | number | Date) =>
    setClaimIncident((prevState) => ({
      ...prevState,
      claimIncident: {
        ...prevState.claimIncident,
        incident: {
          ...prevState.claimIncident.incident,
          [field]: value,
        },
      },
    }));

  const rowProps: RowProps = {
    verticalAlign: "flex-start",
    horizontalAlign: "flex-start",
    horizontalMargin: "0px",
    horizontalGutter: "0px",
    verticalMargin: "0px",
    verticalGutter: "0px",
    allowWrap: true,
  };

  const getPreparerDateInitialValue = () => {
    if (conditionHasValue(claimIncident?.incident?.preparerDate)) {
      return GettingDateWithoutTime(
        new Date(claimIncident?.incident?.preparerDate ?? "")
      );
    } else {
      updateValue("preparerDate", GettingDateWithoutTime(new Date()));
      return GettingDateWithoutTime(new Date());
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Input
            id="id-incidentFinish-preparerName"
            name="incidentFinishPreparerName"
            readOnly={readOnly}
            label="Preparer's Name"
            variant={textFieldVariant}
            value={claimIncident?.incident?.preparerName ?? ""}
            maxLength={300}
            onChangeRawValue={(value) => updateValue("preparerName", value)}
            errorMessage={errorDetails?.preparerName}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Input
            id="id-incidentFinish-preparerTitle"
            name="incidentFinishPreparerTitle"
            readOnly={readOnly}
            label="Preparer's title"
            variant={textFieldVariant}
            value={claimIncident?.incident?.preparerTitle ?? ""}
            maxLength={300}
            onChangeRawValue={(value) => updateValue("preparerTitle", value)}
            errorMessage={errorDetails?.preparerTitle}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Input
            id="id-incidentFinish-preparerCompany"
            name="incidentFinishPreparerCompany"
            readOnly={readOnly}
            label="Preparer's Company"
            variant={textFieldVariant}
            value={claimIncident?.incident?.preparerCompanyName ?? ""}
            maxLength={300}
            onChangeRawValue={(value) =>
              updateValue("preparerCompanyName", value)
            }
            errorMessage={errorDetails?.preparerCompanyName}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 4, xl: 4 }}>
          <InputPhone
            id="id-incidentFinish-preparerPhone"
            name="incidentFinishPreparerPhone"
            readOnly={readOnly}
            phoneLabel="Preparer's Phone number"
            variant={textFieldVariant}
            phoneValue={claimIncident?.incident?.preparerPhone ?? ""}
            maxLength={300}
            onChangePhoneValue={(value) => updateValue("preparerPhone", value)}
            errorMessagePhone={errorDetails?.preparerPhone}
            inputWidth="100%"
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 6, xl: 6 }}>
          <Input
            id="id-incidentFinish-preparerEmail"
            name="incidentFinishPreparerEmail"
            readOnly={readOnly}
            type="email"
            label="Preparer's Email Address"
            variant={textFieldVariant}
            value={claimIncident?.incident?.preparerEmail ?? ""}
            maxLength={300}
            onChangeRawValue={(value) => updateValue("preparerEmail", value)}
            errorMessage={errorDetails?.preparerEmail}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 4, xl: 4 }}>
          <InputDate
            id="id-incidentFinish-preparerDate"
            name="incidentFinishPreparerDate"
            readOnly={readOnly}
            label="Date Submitted"
            variant={textFieldVariant}
            value={getPreparerDateInitialValue()}
            onChangeRawValue={(value) => updateValue("preparerDate", value)}
            errorMessage={errorDetails?.preparerDate}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>

      <Row {...rowProps} horizontalAlign={"flex-start"}>
        <Col breakpoints={{ md: 12, lg: 8, xl: 8 }}>
          <Upload
            label="Upload supporting files (if any)"
            readOnly={readOnly}
            name="uploadIncidentFinishFiles"
            files={files}
            onUploadFileList={setFiles}
            labelFontType="BOLD_CAPTION"
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col breakpoints={{ md: 12, lg: 6, xl: 6 }}>
          <Input
            id="id-incidentFinish-preparerName"
            name="incidentFinishPreparerName"
            readOnly={readOnly}
            label="Signature - Preparer name"
            variant={textFieldVariant}
            value={claimIncident?.incident?.preparerName ?? ""}
            maxLength={300}
            onChangeRawValue={(value) => updateValue("preparerName", value)}
            errorMessage={errorDetails?.preparerName}
            labelFontType="BOLD_CAPTION"
          />
          <Switch
            name="incidentFinishPreparerSignature"
            readOnly={readOnly}
            control={"checkbox"}
            isChecked={
              hasSignature ||
              (readOnly && claimIncident?.incident?.preparerName !== undefined)
            }
            onChange={(e) => setSignature(e.target.checked)}
            className={"incident-checkbox"}
          />
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col horizontalAlign={"flex-start"}>
          <Font>
            Type full name and check the box below to sign this form and agree
            to the submission conditions above.
          </Font>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col>
          <Box className={styles.finish_instructions}>
            <Typography
              variant="body1"
              align="justify"
              className={styles.instructions}
              dangerouslySetInnerHTML={{
                __html: stateReport?.stateReport.finishInstructions ?? "",
              }}
            />
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default IncidentFinish;
