import { FC } from "react";
import { BaseTableInputType } from "../../../dtos/base-table-input-type";
// import { RowKey } from "../BaseGridProperties";
import DefaultCell from "../GridCells/DefaultCell";
import DefaultPhoneCell from "../GridCells/DefaultPhoneCell";
import DefaultCheckboxCell from "../GridCells/DefaultCheckboxCell";
import OptionsCell2 from "../GridCells/OptionsCell2";
import { BaseGridCellTypeProperties } from "./BaseGridCellManagerCellSelector";
import DefaultTagCell from "../GridCells/DefaultTagCell";
import DefaultEmailCell from "../GridCells/DefaultEmailCell";
import DefaultSelectCell from "../GridCells/DefaultSelectCell";
import DefaultPhoneExtCell from "../GridCells/DefaultPhoneExtCell";
import DefaultLinkCell from "../GridCells/DefaultLinkCell";
import DefaultCurrencyCell from "../GridCells/DefaultCurrencyCell";
import IndicationCell from "../GridCells/IndicationCell";
import DefaultSSNCell from "../GridCells/DefaultSSNCell";
import ScoreChipCell2 from "../GridCells/ScoreChipCell2";
import DefaultHTMLCell from "../GridCells/DefaultHTMLCell";
import DefaultDownloadLinkCell from "../GridCells/DefaultDownloadLinkCell";

const BaseGridStaticCells: FC<BaseGridCellTypeProperties> = (
  managedCell: BaseGridCellTypeProperties
) => {
  // There will be more "static" cells
  switch (managedCell.column.type) {
    case BaseTableInputType.CHECKBOX:
      return <DefaultCheckboxCell value={managedCell.value} />;
    case BaseTableInputType.PHONE:
      return <DefaultPhoneCell value={managedCell.value} />;
    case BaseTableInputType.PHONE_AND_EXTENSION:
      return <DefaultPhoneExtCell value={managedCell.value} />;
    case BaseTableInputType.SELECT:
      return (
        <DefaultSelectCell
          value={managedCell.value}
          displayOptions={managedCell.column.displayOptions}
        />
      );
    case BaseTableInputType.EMAIL:
      return <DefaultEmailCell value={managedCell.value} />;
    case BaseTableInputType.TAGS:
      return (
        <DefaultTagCell
          value={managedCell.value}
          displayOptions={managedCell.column.displayOptions}
        />
      );
    case BaseTableInputType.LINK:
      return (
        <DefaultLinkCell
          value={managedCell.value}
          displayValues={managedCell.column.alternateDisplayValues}
        />
      );
    case BaseTableInputType.DOWNLOAD_LINK:
      return (
        <DefaultDownloadLinkCell
          value={managedCell.value}
          displayValues={managedCell.column.alternateDisplayValues}
        />
      );
    case BaseTableInputType.CURRENCY:
      return <DefaultCurrencyCell value={managedCell.value} />;
    case BaseTableInputType.SSN:
      return <DefaultSSNCell value={managedCell.value} />;
    case BaseTableInputType.INDICATION:
      return (
        <IndicationCell
          {...(managedCell as any)}
          uiid={managedCell.uiid}
          row={managedCell.row}
          value={managedCell.value ?? "none"}
        />
      );
    case BaseTableInputType.OPTIONS:
      return (
        <OptionsCell2
          uiid={managedCell.uiid}
          rowKey={managedCell.rowKey}
          rowIndex={managedCell?.rowIndex ?? -1}
          optionType={managedCell.value ?? "none"}
          contextMenuOptionRenderingCondition={null}
          optionsContextActions={null}
          hydratedRow={null}
        />
      );
    case BaseTableInputType.CHIPS:
      return (
        <ScoreChipCell2
          uiid={managedCell.uiid}
          rowKey={managedCell.rowKey}
          rowIndex={managedCell?.rowIndex ?? -1}
          value={managedCell.value ?? 0}
          {...(managedCell as any)}
        />
      );
    case BaseTableInputType.HTML_ELEMENT:
      return <DefaultHTMLCell value={managedCell.value} />;
    default:
      return (
        <DefaultCell
          align={managedCell?.columnProps?.align ?? "left"}
          value={managedCell.value}
        />
      );
  }
};

export default BaseGridStaticCells;
