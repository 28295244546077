import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";

interface CustomProps {
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  className?: string;
  type?: string;
  prefix?: string;
  suffix?: string;
  decimals?: number;
  negatives?: boolean;
  maxValue?: number;
  minValue?: number;
  value?: string;
  thousandSeparator?: boolean;
  tabIndex?: number;
  isAutoComplete?: boolean;
  displayNumericArrows?: boolean;
}

const isInvalidNumberMinValue = (floatValue?: number, minValue?: number) =>
  isNaN(floatValue ?? NaN) ||
  (minValue !== undefined && floatValue !== undefined && floatValue < minValue);

const isInvalidNumberMaxValue = (floatValue?: number, maxValue?: number) =>
  isNaN(floatValue ?? NaN) ||
  (maxValue !== undefined && floatValue !== undefined && floatValue > maxValue);

const NumberFormatCustom = forwardRef<any, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const {
      onChange,
      className,
      prefix,
      suffix,
      decimals,
      type,
      negatives,
      maxValue,
      minValue,
      value: propValue,
      thousandSeparator,
      tabIndex = 0,
      isAutoComplete = true,
      displayNumericArrows = false,
      ...other
    } = props;

    const [value, setValue] = useState(propValue?.toString());
    const [startsWithDot, setStartsWithDot] = useState<boolean>(false);

    const internalOnChange = ({ value, floatValue }) => {
      const targetValue =
        negatives && value === "-" && (floatValue ?? false) === false
          ? value
          : floatValue;
      onChange?.({
        target: {
          value: targetValue,
        },
      });
      setValue(value);

      if ((value as string).at(0) === ".") setStartsWithDot(true);
      else setStartsWithDot(false);
    };

    // const onKeyUpValidation = () => {
    //   const checkLimitValidation =
    //     conditionHasValue(minValue) || conditionHasValue(maxValue);
    //   if (propValue !== undefined && checkLimitValidation) {
    //     if (isInvalidNumberMaxValue(parseFloat(propValue), maxValue)) {
    //       onChange?.({
    //         target: {
    //           value: maxValue,
    //         },
    //       });
    //     }
    //     if (isInvalidNumberMinValue(parseFloat(propValue), minValue)) {
    //       onChange?.({
    //         target: {
    //           value: minValue,
    //         },
    //       });
    //     }
    //   }
    //   props.onBlur?.();
    // };

    const internalOnBlur = () => {
      if (propValue !== undefined && !isEmptyValue(propValue)) {
        //fix for when there is an empty value
        if (isInvalidNumberMaxValue(parseFloat(propValue), maxValue)) {
          internalOnChange({
            value: maxValue?.toString(),
            floatValue: maxValue,
          });
        }
        if (isInvalidNumberMinValue(parseFloat(propValue), minValue)) {
          internalOnChange({
            value: minValue?.toString(),
            floatValue: minValue,
          });
        }
        props.onBlur?.({
          target: {
            value: isInvalidNumberMaxValue(parseFloat(propValue), maxValue)
              ? maxValue
              : isInvalidNumberMinValue(parseFloat(propValue), minValue)
              ? minValue
              : propValue,
          },
        });
      } else {
        props.onBlur?.({
          target: {
            value: null,
          },
        });
      }
    };

    useEffect(() => {
      if (parseFloat(value ?? "0") !== parseFloat(propValue ?? "0"))
        setValue(propValue?.toString());
    }, [propValue]);

    return (
      <NumericFormat
        {...other}
        className={className}
        getInputRef={ref}
        value={value}
        thousandSeparator={!startsWithDot && (thousandSeparator ?? true)}
        valueIsNumericString={!startsWithDot}
        decimalScale={decimals ?? 2}
        prefix={prefix ?? "$"}
        suffix={suffix}
        allowNegative={negatives ?? false}
        fixedDecimalScale={type === "fixedCurrency"}
        tabIndex={tabIndex}
        onValueChange={internalOnChange}
        // onKeyUp={onKeyUpValidation}
        onBlur={internalOnBlur}
        autoComplete={isAutoComplete ? "on" : "off"}
        type={displayNumericArrows ? (type as any) : null}
      />
    );
  }
);
export default NumberFormatCustom;
