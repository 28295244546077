import { FC } from "react";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import Input from "./Input";

type InputMemoProps = {
  id: string;
  name: string;
  value?: any | null;
  label?: string;
  variant?: "filled" | "standard" | "outlined";
  helperText?: string;
  errorMessage?: string[] | null;
  rows?: number;
  resize?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  focus?: boolean;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  inputWidth?: string;
  onChangeRawValue?: (e?: any) => void;
  onChange?: (e?: any) => void;
  tabIndex?: number;
  htmlLabel?: string;
};

const InputMemo: FC<InputMemoProps> = ({
  id,
  name,
  value,
  label,
  variant = "filled",
  helperText = "",
  errorMessage = null,
  rows,
  maxLength,
  readOnly = false,
  onChangeRawValue,
  onChange,
  inputFontType,
  labelFontType,
  inputWidth = "100%",
  resize = true,
  focus = false,
  tabIndex = 0,
  htmlLabel,
}) => {
  const onChangeInput = (e: any) => {
    e.target.value = e.target.value.trimStart();
    onChangeRawValue?.(e.target.value);
    onChange?.(e);
  };

  return (
    <Input
      tabIndex={tabIndex}
      id={id}
      name={name}
      label={label}
      htmlLabel={htmlLabel}
      variant={variant}
      inputWidth={inputWidth}
      value={value}
      maxLength={maxLength}
      onChange={onChangeInput}
      errorMessage={errorMessage}
      helperText={helperText}
      multiline
      resize={resize}
      rows={rows}
      readOnly={readOnly}
      inputFontType={inputFontType}
      labelFontType={labelFontType}
      focus={focus}
    />
  );
};

export default InputMemo;
