import { Paper } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";

export type StyleBoxPoints =
  | 0
  | 0.5
  | 1
  | 5
  | 10
  | 15
  | 20
  | 25
  | 30
  | 35
  | 40
  | 45
  | 50;

type smallPoints = 0 | 1 | 2 | 3 | 4 | 5;

type StyleBoxProps = {
  className?: string;
  elevation?: smallPoints;
  width?: StyleBoxPoints;
  height?: StyleBoxPoints;
  heightFillAvailable?: boolean;
  //margin
  margin?: StyleBoxPoints;
  marginRight?: StyleBoxPoints;
  marginLeft?: StyleBoxPoints;
  marginTop?: StyleBoxPoints;
  marginBottom?: StyleBoxPoints;
  //padding
  padding?: StyleBoxPoints | smallPoints;
  paddingRight?: StyleBoxPoints;
  paddingLeft?: StyleBoxPoints;
  paddingTop?: StyleBoxPoints;
  paddingBottom?: StyleBoxPoints;
  //display
  display?: "flex" | "inline-flex" | "block" | "inline" | "none";
  position?: "static" | "relative" | "fixed" | "absolute" | "sticky";
  right?: StyleBoxPoints;
  left?: StyleBoxPoints;
  top?: StyleBoxPoints;
  bottom?: StyleBoxPoints;
  verticalAlign?: "start" | "center" | "end";
  horizontalAlign?: "start" | "center" | "end" | "space-between";
  //border
  showBorder?: boolean;
  borderRadius?: smallPoints;
  overrideBorderColor?: string;
  overrideBackgroundColor?: string;
  overrideTextColor?: string;
  secondary?: boolean;
  children?: any;
};

const StyleBox: FC<StyleBoxProps> = ({
  className,
  elevation = 0,
  width = 45,
  height,
  margin = 1,
  marginRight,
  marginLeft,
  marginTop,
  marginBottom,
  padding = 1,
  paddingRight,
  paddingLeft,
  paddingTop,
  paddingBottom,
  display = "flex",
  position,
  right,
  left,
  top,
  bottom,
  verticalAlign = "center",
  horizontalAlign = "center",
  showBorder = false,
  borderRadius = 0,
  overrideBorderColor,
  overrideBackgroundColor,
  overrideTextColor,
  secondary = false,
  children,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const getValue = (value) => (value ? `${value}%` : undefined);

  const getValueDouble = (value?: number, fitContent?: boolean) =>
    value ? `${value * 2}%` : fitContent ? "fit-content" : undefined;

  return (
    <Paper
      className={`style_box ${className}`}
      elevation={elevation}
      sx={{
        width: getValueDouble(width, true),
        height: getValueDouble(height, true),
        //margin
        margin: `${margin}%`,
        marginRight: getValue(marginRight),
        marginLeft: getValue(marginLeft),
        marginTop: getValue(marginTop),
        marginBottom: getValue(marginBottom),
        //padding
        padding: `${padding}%`,
        paddingRight: getValue(paddingRight),
        paddingLeft: getValue(paddingLeft),
        paddingTop: getValue(paddingTop),
        paddingBottom: getValue(paddingBottom),
        //display
        display: display,
        position: position,
        right: getValueDouble(right),
        left: getValueDouble(left),
        top: getValueDouble(top),
        bottom: getValueDouble(bottom),
        alignItems: verticalAlign,
        justifyContent: horizontalAlign,
        alignSelf: "center",
        justifySelf: "center",
        overflowWrap: "anywhere",
        //border
        borderWidth: showBorder ? "1px" : 0,
        borderStyle: showBorder ? "solid" : "none",
        borderRadius: `${borderRadius}px`,
        //colors
        borderColor: `${overrideBorderColor ?? theme?.borderColor} !important`,
        backgroundColor: `${
          overrideBackgroundColor ??
          (secondary ? theme?.secondaryBackground : theme?.primaryBackground)
        } !important`,
        color: `${overrideTextColor ?? theme?.textColor} !important`,
      }}
    >
      {children}
    </Paper>
  );
};

export default StyleBox;
